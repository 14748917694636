import axios from "axios";
import config from "../config";

/**
 * IMPORANT
 * For Test Environment use config.devS3Url & config.devUrl
 * For Prod Environment use config.S3Url && config.baseUrl
 * For Using Local Server use config.devS3Url & config.localUrl
 * change below variable while switching environments
 */

export const s3Url = config.S3Url;
export const domain = config.baseUrl;

// axios.defaults.headers.post['Accept'] = 'application/json';
const api = axios.create({
  baseURL: domain,
  headers: {
    "Content-Type": "application/json",
  },
});

// http response for request
// add tokens and keys to request headers
api.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("token");
    config.headers["authorization"] = token;
    config.headers["Accept"] = "application/json";
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// http response Interceptors
// do something after recieving response from api
api.interceptors.response.use(
  (response) => response?.data,
  (error) => {
    if (!error.response) {
      return Promise.reject(error);
    } else if (error.response) {
      switch (error.response.status) {
        case 401:
          // prevent logout on 401 temporarily
          // localStorage.clear();
          // window.location.reload();
          break;
        default:
          break;
      }
      return Promise.reject(error);
    }
  }
);

export { api };
