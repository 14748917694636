export const calculateAge = (date) => {
  if (date) {
    let currentYear = new Date().getFullYear();
    let doy = new Date(date).getFullYear();
    let ageCount = currentYear - doy;
    return ageCount > 0 ? ageCount : 0;
  } else {
    return 0;
  }
};

export const disableFutureDates = (date) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return date.getTime() > today.getTime();
};
