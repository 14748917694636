import React, { useState } from "react";
import moment from "moment-timezone";
import { mdiPlus, mdiTrashCan, mdiDownload, mdiEye } from "@mdi/js";
import Icon from "@mdi/react";
import { s3Url } from "../../services/api.service";
import DateField from "../Common/Input/DateField";
import { getDailyDarshan } from "./actionCreator";
import DatePickerField from "../Common/Rsuite-Datepicker";
import { saveAs } from "file-saver";
import ImgPreviewModal from "../Common/ImgPreviewModal/ImgPreviewModal";

const DailyDarshanGallery = (props) => {
  const {
    lastActiveDarshan,
    openEditDarshanModal,
    removeDailyDarshan,
    setLastActiveDarshan,
    selectedDate,
  } = props;

  const [openImgPreviewModal, setOpenImgPreviewModal] = useState(false);
  const [previewImg, setPreviewImg] = useState("");

  const [darshanDate, setDarshanDate] = useState(
    moment(selectedDate).tz("utc").startOf("day").toISOString()
  );

  const handleDateChange = async (_date) => {
    setDarshanDate(moment(_date).startOf("day").toISOString());
    const result = await getDailyDarshan(
      moment(_date).startOf("day").toISOString()
    );
    if (result.data) {
      setLastActiveDarshan(result.data);
    } else {
      setLastActiveDarshan([]);
    }
  };

  const downloadCompressImg = (eachUrl) => {
    const url = eachUrl.includes("https://") ? eachUrl : `${s3Url}${eachUrl}`;
    saveAs(url);
  };

  const downloadHDImg = (eachUrl) => {
    const url = eachUrl.includes("https://") ? eachUrl : `${s3Url}${eachUrl}`;
    saveAs(url);
  };

  const handleOpenImgPreview = (eachUrl) => {
    setPreviewImg(
      eachUrl.includes("https://") ? eachUrl : `${s3Url}${eachUrl}`
    );
    setOpenImgPreviewModal(!openImgPreviewModal);
  };

  const handleClosePreview = () => {
    setOpenImgPreviewModal(false);
  };

  // Function to extract the last file name from the URL
  const extractFileName = (url) => {
    return url.substring(url.lastIndexOf("/") + 1);
  };

  return (
    <>
      <div className="bg-white w-full px-10 py-[30px] rounded-[10px] shadow-[1px_-1px_10px_0px_rgba(0,0,0,0.10)]">
        {/* First Part - Compress */}
        <div className="flex justify-between items-start">
          <div className="text-[20px] text-[#212121] mb-[26px]">
            <div>
              {/* <DateField
              id="13"
              className="!h-10 placeholder:text-black900 bg-white outline-none mb-2"
              placeholder="Darshan Date"
              value={
                darshanDate ? moment(darshanDate).format("DD/MM/YYYY") : ""
              }
              onChange={(_date) => handleDateChange(_date)}
              timeFormat={false}
            /> */}
              <DatePickerField
                placeholder={"Darshan Date"}
                value={darshanDate ? new Date(darshanDate) : ""}
                onChange={(_date) => handleDateChange(_date)}
              />
            </div>
            <div className="text-deep-orange-400">Compress</div>
          </div>
          <div className="flex items-center mr-3">
            <div className="w-10 h-10 bg-[#F8F8F8] border p-1.5 rounded-[50%] border-solid border-transparent flex items-center justify-center hover:opacity-100 transition-all duration-300 ease-[ease] hover:border-[black] hover:shadow-[0px_2px_4px_0px_#00000045] cursor-pointer">
              <button onClick={() => openEditDarshanModal()}>
                <Icon path={mdiPlus} size={"24px"} />
              </button>
            </div>
          </div>
        </div>
        <div>
          <div className="flex-grow">
            <div className="flex-grow">
              <div className="flex flex-wrap m-1 md:-m-2">
                {lastActiveDarshan.fileUrls?.length > 0 ? (
                  lastActiveDarshan.fileUrls.map((eachUrl, indx) => {
                    const url = eachUrl.includes("https://")
                      ? eachUrl
                      : `${s3Url}${eachUrl}`;
                    return (
                      <div
                        key={"darshanimg" + indx}
                        className="h-full p-2"
                        style={{ width: "16%" }}
                      >
                        <div className="mb-1 text-black">{`${
                          indx + 1
                        }) ${extractFileName(url)}`}</div>
                        <div className="w-full rounded-[10px] overflow-hidden h-full relative">
                          <img
                            alt="gallery"
                            className="block h-full w-full rounded-lg object-cover object-center"
                            src={`${url}`}
                          />
                          <div className="absolute w-full h-full flex items-center justify-center gap-x-3 bg-[rgba(0,0,0,0.60)] opacity-0 inset-0 hover:opacity-100 transition-all duration-300 ease-[ease]">
                            <button
                              className="w-10 h-10 bg-white flex items-center justify-center p-1.5 rounded-[50%] transition-all duration-300 ease-[ease] hover:scale-[1.06]"
                              onClick={() => handleOpenImgPreview(eachUrl)}
                            >
                              <Icon path={mdiEye} size={1} />
                            </button>

                            <button
                              className="w-10 h-10 bg-white flex items-center justify-center p-1.5 rounded-[50%] transition-all duration-300 ease-[ease] hover:scale-[1.06]"
                              onClick={() => downloadCompressImg(eachUrl)}
                            >
                              <Icon path={mdiDownload} size={1} />
                            </button>

                            <button
                              className="w-10 h-10 bg-white flex items-center justify-center p-1.5 rounded-[50%] transition-all duration-300 ease-[ease] hover:scale-[1.06]"
                              onClick={() =>
                                removeDailyDarshan(
                                  eachUrl,
                                  lastActiveDarshan.date,
                                  "compress"
                                )
                              }
                            >
                              <Icon path={mdiTrashCan} size={1} />
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="text-xs text-[#A4A4A4]">
                    DailyDarshan images not found!
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Second Part - HD */}
        <div className="flex justify-between mt-10">
          <div className="text-[20px] text-[#212121] mb-[26px]">
            <div>
              {/* <DateField
              id="13"
              className="!h-10 placeholder:text-black900 bg-white outline-none mb-2"
              placeholder="Darshan Date"
              value={
                darshanDate ? moment(darshanDate).format("DD/MM/YYYY") : ""
              }
              onChange={(_date) => handleDateChange(_date)}
              timeFormat={false}
            /> */}
            </div>
            <div className="text-center text-deep-orange-400">HD</div>
          </div>
          {/* <div className="flex items-center mr-3">
          <div className="w-10 h-10 bg-[#F8F8F8] border p-1.5 rounded-[50%] border-solid border-transparent flex items-center justify-center hover:opacity-100 transition-all duration-300 ease-[ease] hover:border-[black] hover:shadow-[0px_2px_4px_0px_#00000045] cursor-pointer">
            <button onClick={() => openEditDarshanModal()}>
              <Icon path={mdiPlus} size={"24px"} />
            </button>
          </div>
        </div> */}
        </div>
        <div>
          <div className="flex-grow">
            <div className="flex-grow">
              <div className="flex flex-wrap m-1 md:-m-2">
                {lastActiveDarshan.fileHdUrls?.length > 0 ? (
                  lastActiveDarshan.fileHdUrls.map((eachUrl, indx) => {
                    return (
                      <>
                        <div
                          key={"darshanimg" + indx}
                          className="h-full p-2"
                          style={{ width: "16%" }}
                        >
                          <div className="mb-1 text-black">{`${
                            indx + 1
                          }) ${extractFileName(eachUrl)}`}</div>
                          <div className="w-full rounded-[10px] overflow-hidden h-full relative">
                            <img
                              alt="gallery"
                              className="block h-full w-full rounded-lg object-cover object-center"
                              src={`${eachUrl}`}
                            />
                            <div className="absolute w-full h-full flex items-center justify-center gap-x-3 bg-[rgba(0,0,0,0.60)] opacity-0 inset-0 hover:opacity-100 transition-all duration-300 ease-[ease]">
                              <button
                                className="w-10 h-10 bg-white flex items-center justify-center p-1.5 rounded-[50%] transition-all duration-300 ease-[ease] hover:scale-[1.06]"
                                onClick={() => handleOpenImgPreview(eachUrl)}
                              >
                                <Icon path={mdiEye} size={1} />
                              </button>

                              <button
                                className="w-10 h-10 bg-white flex items-center justify-center p-1.5 rounded-[50%] transition-all duration-300 ease-[ease] hover:scale-[1.06]"
                                onClick={() => downloadHDImg(eachUrl)}
                              >
                                <Icon path={mdiDownload} size={1} />
                              </button>
                              <button
                                className="w-10 h-10 bg-white flex items-center justify-center p-1.5 rounded-[50%] transition-all duration-300 ease-[ease] hover:scale-[1.06]"
                                onClick={() =>
                                  removeDailyDarshan(
                                    eachUrl,
                                    lastActiveDarshan.date,
                                    "hd"
                                  )
                                }
                              >
                                <Icon path={mdiTrashCan} size={1} />
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <div className="text-xs text-[#A4A4A4]">
                    DailyDarshan images not found!
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ImgPreviewModal
        previewImg={previewImg}
        closeModal={handleClosePreview}
        show={openImgPreviewModal}
      />
    </>
  );
};

export default DailyDarshanGallery;
