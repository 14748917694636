import React, { useState, useEffect } from "react";
import { mdiTrayArrowUp } from "@mdi/js";
import { mdiPencil } from "@mdi/js";
import { mdiTrashCan, mdiDownload, mdiEye } from "@mdi/js";
import Icon from "@mdi/react";
import moment from "moment-timezone";
import _ from "lodash";

import EventBannerUpload from "./EventBannerUpload";
import LoaderBar from "../Common/LoaderBar";
import { toastr } from "../../services/common.service";
import {
  deleteEventBanner,
  getEventBanners,
  postBannerDetails,
  updateBannerDetail,
} from "./actionCreator";
import { s3Url } from "../../services/api.service";
import { saveAs } from "file-saver";
import MonthPicker from "../Common/Input/MonthPicker";
import { DateField, ImgPreviewModal, SelectField, YearPicker } from "..";

const filterOptions = [
  {
    label: "Upcoming",
    value: "upcoming",
  },
  {
    label: "Past",
    value: "past",
  },
];

const EventBanners = () => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState();
  const [selectEventTimeFilter, setSelectEventTimeFilter] = useState(
    filterOptions[0].value
  );

  const [openImgPreviewModal, setOpenImgPreviewModal] = useState(false);
  const [previewImg, setPreviewImg] = useState("");

  const uploadEventBanner = async (files, event) => {
    try {
      if (!event?.title || !event?.effectiveStart || !event?.effectiveEnd) {
        toastr.show("Please Select Details", "error");
        return;
      }
      if (
        (!event._id && !files?.length) ||
        (event?._id && event?.uploadedFile === "" && !files?.length)
      ) {
        toastr.show("Please Select Files", "error");
        return;
      }
      setIsLoading(true);
      let response = {};
      delete event?.uploadedFile;
      if (event._id && !files?.length) {
        response = await updateBannerDetail(event);
      } else {
        response = await postBannerDetails(files, event);
      }
      if (response.data) {
        toastr.show("Success !!", "success");

        let _upcomingEvents = _.cloneDeep(upcomingEvents);
        if (event?._id) {
          const updatedEventIndex = _upcomingEvents.findIndex(
            (d) => d._id === event._id
          );
          _upcomingEvents.splice(updatedEventIndex, 1, response.data);
        } else {
          _upcomingEvents = [response.data, ..._upcomingEvents];
        }
        setUpcomingEvents(_upcomingEvents);
        setSelectedEvent(null);
        setOpen(false);
      } else {
        toastr.show("Failed !!", "error");
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error uploading files:", error);
      toastr.show("Failed !!", "error");
      setIsLoading(false);
    }
  };

  const removeUpcomingEvent = async (eventId) => {
    try {
      setIsLoading(true);
      const response = await deleteEventBanner(eventId);
      if (response.data) {
        toastr.show("Success !!", "success");
        const _upcomingEvents = _.cloneDeep(upcomingEvents);
        const deletedEventIndex = _upcomingEvents.findIndex(
          (d) => d._id === eventId
        );
        if (deletedEventIndex > -1) {
          _upcomingEvents.splice(deletedEventIndex, 1);
          setUpcomingEvents(_upcomingEvents);
        }
        setOpen(false);
      } else {
        toastr.show("Failed !!", "error");
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error uploading files:", error);
      toastr.show("Failed !!", "error");
      setIsLoading(false);
    }
  };

  const loadUpcomingEvents = async () => {
    try {
      const response = await getEventBanners();
      if (response.data) {
        setUpcomingEvents(response.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error Fetching Darshan:", error);
      setIsLoading(false);
    }
  };

  const handleDownload = (imageUrl) => {
    saveAs(`${s3Url}${imageUrl}`);
  };

  const handleOpenImgPreview = (eachUrl) => {
    setPreviewImg(
      eachUrl.includes("https://") ? eachUrl : `${s3Url}${eachUrl}`
    );
    setOpenImgPreviewModal(!openImgPreviewModal);
  };

  const handleClosePreview = () => {
    setOpenImgPreviewModal(false);
  };

  useEffect(() => {
    loadUpcomingEvents();
  }, []);

  return (
    <>
      {isLoading && <LoaderBar />}
      <div className="h-[calc(100vh_-_81px)]  p-6 xl:p-9 2xl:p-[50px] bg-[#F8F8F8] overflow-auto">
        <div className="flex items-center justify-between pb-[15px] mb-[20px] border-b-[#D7D7D7] border-b border-solid">
          <div className="text-[25px] text-[#212121]">Event Banner</div>
          <a
            className="w-10 h-10 bg-white border p-1.5 rounded-[50%] border-solid border-transparent flex items-center justify-center hover:opacity-100 transition-all duration-300 ease-[ease] hover:border-[black] hover:shadow-[0px_2px_4px_0px_#00000045]"
            onClick={() => {
              setOpen(!open);
            }}
          >
            <Icon path={mdiTrayArrowUp} size={0.9} />
          </a>
        </div>
        {upcomingEvents.length > 0 ? (
          <div className="bg-white w-full px-10 py-[30px] rounded-[10px] shadow-[1px_-1px_10px_0px_rgba(0,0,0,0.10)]">
            <div className="flex flex-wrap -m-2.5 overflow-hidden">
              {upcomingEvents.map((upcomingEvent, index) => {
                return (
                  <div key={"event" + index} className="w-1/4 h-full p-2.5">
                    <div className="rounded-[10px] bg-[#ffa8a8] overflow-hidden h-full relative">
                      <img
                        src={`${s3Url}${upcomingEvent.imageUrl}`}
                        alt={upcomingEvent.title}
                        className="w-full h-full object-cover"
                      />
                      <div className="absolute w-full h-full flex items-center justify-center gap-x-3 bg-[rgba(0,0,0,0.60)] opacity-0 inset-0 hover:opacity-100 transition-all duration-300 ease-[ease]">
                        <a className="w-10 h-10 bg-white flex items-center justify-center p-1.5 rounded-[50%] transition-all duration-300 ease-[ease] hover:scale-[1.06]">
                          <button
                            onClick={() =>
                              handleOpenImgPreview(upcomingEvent.imageUrl)
                            }
                          >
                            <Icon path={mdiEye} size={1} />
                          </button>
                        </a>
                        <a className="w-10 h-10 bg-white flex items-center justify-center p-1.5 rounded-[50%] transition-all duration-300 ease-[ease] hover:scale-[1.06]">
                          <button
                            onClick={() => {
                              setSelectedEvent(upcomingEvent);
                              setOpen(true);
                            }}
                          >
                            <Icon path={mdiPencil} size={1} />
                          </button>
                        </a>
                        <a className="w-10 h-10 bg-white flex items-center justify-center p-1.5 rounded-[50%] transition-all duration-300 ease-[ease] hover:scale-[1.06]">
                          <button
                            onClick={() =>
                              removeUpcomingEvent(upcomingEvent._id)
                            }
                          >
                            <Icon path={mdiTrashCan} size={1} />
                          </button>
                        </a>
                        <a className="w-10 h-10 bg-white flex items-center justify-center p-1.5 rounded-[50%] transition-all duration-300 ease-[ease] hover:scale-[1.06]">
                          <button
                            onClick={() =>
                              handleDownload(upcomingEvent.imageUrl)
                            }
                          >
                            <Icon path={mdiDownload} size={1} />
                          </button>
                        </a>
                      </div>
                    </div>
                    <div className="m-1 mt-2">
                      <div className="text-[14px]">{upcomingEvent.title}</div>
                      <div className="text-[#A4A4A4] text-[11px]">
                        Date:{" "}
                        <span>
                          {moment(upcomingEvent.effectiveStart).format(
                            "DD-MM-YYYY"
                          )}{" "}
                          to{" "}
                          {moment(upcomingEvent.effectiveEnd).format(
                            "DD-MM-YYYY"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <EventBannerUpload
        open={open}
        setOpen={setOpen}
        uploadEventBanner={uploadEventBanner}
        selectedEvent={selectedEvent}
        setSelectedEvent={setSelectedEvent}
      />

      <ImgPreviewModal
        previewImg={previewImg}
        closeModal={handleClosePreview}
        show={openImgPreviewModal}
      />
    </>
  );
};

export default EventBanners;
