import { Button } from "@material-tailwind/react";
import React, { useEffect, useRef, useState } from "react";
import { Switch } from "@headlessui/react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Icon } from "@iconify/react";
import { motion } from "framer-motion";
import AddAudioModal from "./AddAudioModal";
import { useParams, useNavigate } from "react-router-dom";
import {
  changeAudioVisibility,
  deleteAlbumAudio,
  getAlbumListByID,
  reorderAudio,
} from "./actionCreator";
import DeleteModal from "../../Common/DeleteModal/DeleteModal";
import { toastr } from "../../../services/common.service";
import { s3Url } from "../../../services/api.service";

const columnHelper = createColumnHelper();

const DraggableRow = ({ row, reorderRow }) => {
  const [, dropRef] = useDrop({
    accept: "row",
    drop: (draggedRow) => reorderRow(draggedRow.index, row.index),
  });

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => row,
    type: "row",
  });

  return (
    <motion.tr
      ref={previewRef}
      initial={{ opacity: 1 }}
      animate={{ opacity: isDragging ? 0.5 : 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
    >
      <td ref={dropRef} className="text-start w-[50px]">
        <button ref={dragRef} className="cursor-grab">
          <Icon icon="grommet-icons:drag" className="text-xl" />
        </button>
      </td>
      {row.getVisibleCells().map((cell) => (
        <td key={cell.id} className="p-2">
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      ))}
    </motion.tr>
  );
};

const EditPlaylist = () => {
  const navigate = useNavigate();

  let { id } = useParams();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [selectedAudio, setSelectedAudio] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const audioRefs = useRef({});
  const playButtonRefs = useRef({});
  const [currentPlayingId, setCurrentPlayingId] = useState(null);
  const reorderRow = (draggedRowIndex, targetRowIndex) => {
    data.splice(targetRowIndex, 0, data.splice(draggedRowIndex, 1)[0]);
    setData([...data]);
    reorderAudio({
      albumId: id,
      dragIndex: draggedRowIndex,
      hoverIndex: targetRowIndex,
    })
      .then(() => {})
      .catch((err) => {
        toastr.show("Something went wrong", "error");
      });
  };

  const openCreateLinkModal = (_id = null) => {
    if (_id) {
      const audio = data.find((list) => list._id + "" === _id + "");
      setSelectedAudio(audio);
    } else {
      setSelectedAudio({});
    }
    setOpen(!open);
  };

  const handleCloseModal = async (status) => {
    if (status) {
      const payload = {
        _id: deleteId,
      };
      const result = await deleteAlbumAudio(payload);
      if (result?.data) {
        toastr.show("Deleted!!", "success");
        fetchAlbumAudioByID();
      } else {
        toastr.show("Something went wrong", "error");
      }
    }
    setShowDeleteModal(false);
    setDeleteId("");
  };

  const handleOpenDeleteModal = (id) => {
    setDeleteId(id);
    setShowDeleteModal(!showDeleteModal);
  };
  const fetchAlbumAudioByID = () => {
    const fetchAudio = async () => {
      const res = await getAlbumListByID(id);
      setData(res?.data?.audiosList);
    };
    fetchAudio();
  };
  useEffect(() => {
    fetchAlbumAudioByID();
  }, [id]);

  const changeVisibility = async (_id, visibility) => {
    const changeVisibility = await changeAudioVisibility({ _id, visibility });
    if (changeVisibility.data) {
      toastr.show("Visibility Updated!", "success");
      const newList = data.map((d) => {
        if (d._id + "" === _id + "") {
          return { ...d, visibility };
        }
        return d;
      });
      setData(newList);
    } else {
      toastr.show("Something went wrong", "error");
    }
  };

  const togglePlayPause = (id, url) => {
    const audio = audioRefs.current[id];
    audio.src = url;

    const playBtn = playButtonRefs.current[id];
    playBtn.className = "hidden";
    /* if (currentPlayingId && currentPlayingId !== id) {
          const currentAudio = audioRefs.current[currentPlayingId];
          currentAudio.pause();
          currentAudio.currentTime = 0;
        }
        if (audio.paused) {
          audio.play();
          setCurrentPlayingId(id);
        } else {
          audio.pause();
          setCurrentPlayingId(null);
        } */
  };

  const columns = [
    columnHelper.accessor("title", {
      cell: (info) => info.getValue(),
      header: () => <span>Title</span>,
    }),
    columnHelper.accessor("imageURL", {
      cell: (info) => (
        <img
          src={
            info.getValue()
              ? `${s3Url}${info.getValue()}`
              : "/assets/image/no-thumbnail.png"
          }
          className="h-[50px] rounded-lg"
        />
      ),
      header: () => <span>Thumbnail</span>,
    }),
    columnHelper.accessor("singer", {
      cell: (info) => info.getValue(),
      header: () => <span>Singer</span>,
    }),
    columnHelper.display({
      header: () => <span>Play</span>,
      id: "play",
      cell: (props) => {
        return (
          <button
            onClick={() => {
              togglePlayPause(
                props.row.original._id,
                `${props.row.original.audioURL}`
              );
            }}
          >
            <div
              ref={(el) =>
                (playButtonRefs.current[props.row.original._id] = el)
              }
            >
              <Icon icon="ic:baseline-play-arrow" className="text-2xl" />
            </div>
            <audio
              ref={(el) => (audioRefs.current[props.row.original._id] = el)}
              controls
            >
              <source type="audio/mp3" />
            </audio>
          </button>
        );
      },
    }),
    columnHelper.accessor("visibility", {
      cell: (info) => (
        <Switch
          checked={info.getValue()}
          onChange={() =>
            changeVisibility(info.row.original._id, !info.getValue())
          }
          className={`${
            info.getValue() ? "bg-blue-600" : "bg-gray-200"
          } relative inline-flex h-6 w-11 items-center rounded-full`}
        >
          <span
            className={`${
              info.getValue() ? "translate-x-6" : "translate-x-1"
            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
          />
        </Switch>
      ),
      header: () => <span>Visibility</span>,
    }),
    columnHelper.display({
      header: () => <span className="text-center">Actions</span>,
      id: "actions",
      cell: (props) => {
        return (
          <span className="flex items-center justify-center">
            <span className="w-9 h-9 border border-black900 rounded-xl transition-all duration-150 flex justify-center items-center mr-2 cursor-pointer group text-black900">
              <button>
                <Icon
                  icon="ic:baseline-edit"
                  className="text-2xl transition-all duration-150 text-black900"
                  onClick={() => openCreateLinkModal(props.row.original?._id)}
                />
              </button>
            </span>
            <span className="w-9 h-9 border border-black900 rounded-xl transition-all duration-150 flex justify-center items-center mr-2 cursor-pointer group hover:text-black900">
              <button>
                <Icon
                  icon="material-symbols:delete"
                  className="transition-all duration-150 text-2xl text-black900"
                  onClick={() => handleOpenDeleteModal(props.row.original?._id)}
                />
              </button>
            </span>
          </span>
        );
      },
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id,
  });

  return (
    <>
      <div className="h-[calc(100vh_-_81px)] p-10 bg-[#F8F8F8] overflow-auto">
        <div className="flex items-center justify-between pb-[15px] mb-[20px] border-b-[#D7D7D7] border-b border-solid">
          <div className="flex items-center">
            <Icon
              icon="icon-park-solid:left-c"
              className="text-3xl mr-3 cursor-pointer"
              onClick={() => navigate("/audio/album")}
            />
            <div className="text-[25px] text-[#212121]">Audio</div>
          </div>
          <Button
            color="blue"
            className="flex items-center min-w-[150px]"
            onClick={() => openCreateLinkModal()}
          >
            <Icon icon="ic:baseline-plus" className="mr-1 text-2xl" /> Add Audio
          </Button>
        </div>

        <div className="bg-white w-full px-6 py-[30px] overflow-hidden rounded-[10px] shadow-[1px_-1px_10px_0px_rgba(0,0,0,0.10)]">
          <div className="overflow-auto">
            <DndProvider backend={HTML5Backend}>
              <table className="w-full">
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => {
                    return (
                      <tr className="border-b border-stone-200 text-sm bg-[#F2F2F2] text-[#535353]">
                        <th className="text-left font-semibold p-2 min-w-[50px]"></th>
                        {headerGroup.headers.map((header) => {
                          return (
                            <th
                              key={header.id}
                              colSpan={header.colSpan}
                              className="text-left font-semibold p-2"
                            >
                              {header.isPlaceholder ? null : (
                                <div
                                  {...{
                                    className: header.column.getCanSort()
                                      ? "cursor-pointer select-none"
                                      : "last:text-center",
                                  }}
                                >
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                                </div>
                              )}
                            </th>
                          );
                        })}
                      </tr>
                    );
                  })}
                </thead>
                <tbody className="last:!border-b-0">
                  {table.getRowModel().rows.map((row) => (
                    <DraggableRow
                      key={row.id}
                      row={row}
                      reorderRow={reorderRow}
                    />
                  ))}
                </tbody>
              </table>
            </DndProvider>
          </div>
        </div>
      </div>
      <DeleteModal open={showDeleteModal} handleCloseModal={handleCloseModal} />
      <AddAudioModal
        open={open}
        setOpen={setOpen}
        albumId={id}
        selectedAudio={selectedAudio}
        refreshData={fetchAlbumAudioByID}
      />
    </>
  );
};

export default EditPlaylist;
