import { api } from "../../services/api.service";

export const getBookIndex = async (id, isSubIndex) => {
    let url = `books/index/${id}`;
    if (isSubIndex) url = url + "?isSubIndex=true";
    const response = await api.get(url);
    return response;
}

export const createBookIndex = async (data) => {
  const response = await api.post("books/create/index", data);
  return response;
};
  
export const updateBookIndexTitle = async (id, data) => {
  const response = await api.post(`books/update/index/${id}`, data);
  return response;
};

export const deleteBookIndex = async (id) => {
  const response = await api.delete(`books/delete/index/${id}`);
  return response;
}

export const getBookContent = async (id) => {
  const response = await api.get(`books/content/${id}`);
  return response;
}

export const createBookContent = async (data) => {
  const response = await api.post("books/content", data);
  return response;
}

export const updateBookContent = async (id, data) => {
  const response = await api.post(`books/update/content/${id}`, data);
  return response;
}

export const changeBookIndexOrder = async (data) => {
  const response = await api.post(`books/change-order`, data);
  return response;
}
