import React, { useState, useEffect } from "react";
import { mdiTrayArrowUp } from "@mdi/js";
import { mdiPencil } from "@mdi/js";
import { mdiTrashCan, mdiEye } from "@mdi/js";
import Icon from "@mdi/react";
import moment from "moment-timezone";
import lodash from "lodash";

import EventBannerUpload from "./EventBannerUpload";
import LoaderBar from "../Common/LoaderBar";
import { toastr } from "../../services/common.service";
import {
  deleteSabhaPost,
  getSabhaPosts,
  postEventDetails,
  updateEventDetail,
} from "./actionCreator";
import { s3Url } from "../../services/api.service";
import MonthPicker from "../Common/Input/MonthPicker";
import { DateField, SelectField, YearPicker } from "..";
import ImgPreviewModal from "../Common/ImgPreviewModal/ImgPreviewModal";

const eventStatusOptions = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Upcoming",
    value: "upcoming",
  },
  {
    label: "Past",
    value: "past",
  },
];

const timeOptions = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Month",
    value: "month",
  },
  {
    label: "Year",
    value: "year",
  },
];

const SabhaPosts = () => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState();
  const [filteredEventList, setFilteredEventList] = useState(null);
  const [filterByTime, setFilterByTime] = useState(null);
  const [filterByStatus, setFilterByStatus] = useState(eventStatusOptions[1]);
  const [filterbyMonth, setFilterByMonth] = useState(null);
  const [filterbyYear, setFilterByYear] = useState(null);
  const [openImgPreviewModal, setOpenImgPreviewModal] = useState(false);
  const [imgURL, setImgURL] = useState(null);

  const handleTimeFilter = (val) => {
    setFilterByStatus(null);
    setFilterByTime(val);
  };

  useEffect(() => {}, []);

  const handleOpenImgPreview = () => {
    setOpenImgPreviewModal(!openImgPreviewModal);
  };

  const handleClosePreview = () => {
    setOpenImgPreviewModal(false);
  };

  const handleFilterByStatus = (event) => {
    setFilterByTime(null);
    setFilterByStatus(event);
    let events = lodash.cloneDeep(filteredEventList);
    let currentDate = new Date();
    switch (event.value) {
      case "upcoming":
        if (events.length > 0) {
          let upcomingEvents = events.filter(
            (eventTime) => new Date(eventTime.effectiveEnd) >= currentDate
          );
          return setUpcomingEvents(upcomingEvents);
        }
        break;
      case "past":
        if (events.length > 0) {
          let pastEvents = events.filter(
            (eventTime) => new Date(eventTime.effectiveEnd) < currentDate
          );
          return setUpcomingEvents(pastEvents);
        }
        break;

      default:
        return setUpcomingEvents(events);
    }
  };

  const handleChangeTime = (date, format) => {
    let selectedDate = new Date(date);
    let _upcomingEvents = lodash.cloneDeep(filteredEventList);
    switch (format) {
      case "month":
        let formatedMonth = moment(selectedDate).format("YYYY-MM");
        setFilterByMonth(formatedMonth);
        if (_upcomingEvents.length > 0) {
          let filteredData = _upcomingEvents.filter(
            (event) =>
              moment(event.effectiveStart).format("YYYY-MM") === formatedMonth
          );
          return setUpcomingEvents(filteredData);
        }

        break;

      case "year":
        let formatedYear = moment(selectedDate).format("YYYY");
        setFilterByYear(formatedYear);
        if (_upcomingEvents.length > 0) {
          let filteredData = _upcomingEvents.filter(
            (event) =>
              moment(event.effectiveStart).format("YYYY") === formatedYear
          );
          return setUpcomingEvents(filteredData);
        }
        break;

      default:
        return setUpcomingEvents(_upcomingEvents);
    }
  };

  const handleFilterByMonth = (date) => {
    let isoDate = date.toISOString();
    handleChangeTime(isoDate, "month");
  };
  const handleFilterByYear = (date) => {
    let isoDate = date.toISOString();

    handleChangeTime(isoDate, "year");
  };

  const uploadEventBanner = async (files, event) => {
    try {
      if (!event?.title || !event?.effectiveStart || !event?.effectiveEnd) {
        toastr.show("Please Select Details", "error");
        return;
      }
      if (
        (!event._id && !files?.length) ||
        (event?._id && event?.uploadedFile === "" && !files?.length)
      ) {
        toastr.show("Please Select Files", "error");
        return;
      }
      setIsLoading(true);
      let response = {};
      delete event?.uploadedFile;
      if (event._id && !files?.length) {
        response = await updateEventDetail(event);
      } else {
        response = await postEventDetails(files, event);
      }

      if (response.data) {
        toastr.show("Success !!", "success");

        let _upcomingEvents = lodash.cloneDeep(upcomingEvents);
        if (event?._id) {
          const updatedEventIndex = _upcomingEvents.findIndex(
            (d) => d._id === event._id
          );
          _upcomingEvents.splice(updatedEventIndex, 1, response.data);
        } else {
          _upcomingEvents = [response.data, ..._upcomingEvents];
        }
        setUpcomingEvents(_upcomingEvents);
        setFilteredEventList(_upcomingEvents);
        setSelectedEvent(null);
        setOpen(false);
      } else {
        toastr.show("Failed !!", "error");
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error uploading files:", error);
      toastr.show("Failed !!", "error");
      setIsLoading(false);
    }
  };

  const removeUpcomingEvent = async (eventId) => {
    try {
      setIsLoading(true);
      const response = await deleteSabhaPost(eventId);
      if (response.data) {
        toastr.show("Success !!", "success");
        const _upcomingEvents = lodash.cloneDeep(upcomingEvents);
        const deletedEventIndex = _upcomingEvents.findIndex(
          (d) => d._id === eventId
        );
        if (deletedEventIndex > -1) {
          _upcomingEvents.splice(deletedEventIndex, 1);
          setUpcomingEvents(_upcomingEvents);
          setFilteredEventList(_upcomingEvents);
        }
        setOpen(false);
      } else {
        toastr.show("Failed !!", "error");
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error uploading files:", error);
      toastr.show("Failed !!", "error");
      setIsLoading(false);
    }
  };

  const loadUpcomingEvents = async () => {
    try {
      const response = await getSabhaPosts();
      if (response.data) {
        setFilteredEventList(response.data);
        let currentDate = new Date();
        let filterData = await response.data.filter(
          (item) => new Date(item?.effectiveEnd) > currentDate
        );
        setUpcomingEvents(filterData);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error Fetching Darshan:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadUpcomingEvents();
  }, []);

  useEffect(() => {
    const _upcomingEvents = lodash.cloneDeep(filteredEventList);
    if ((filterByTime?.value || filterByStatus) === "all") {
      setUpcomingEvents(_upcomingEvents);
      setFilterByMonth(null);
      setFilterByYear(null);
    }
  }, [filterByTime]);

  return (
    <>
      {isLoading && <LoaderBar />}
      <div className="h-[calc(100vh_-_81px)]  p-6 xl:p-9 2xl:p-[50px] bg-[#F8F8F8] overflow-auto">
        <div className="flex items-center justify-between pb-[15px] mb-[20px] border-b-[#D7D7D7] border-b border-solid">
          <div className="text-[25px] text-[#212121]">Upcomming Events</div>
          <a
            className="w-10 h-10 bg-white border p-1.5 rounded-[50%] border-solid border-transparent flex items-center justify-center hover:opacity-100 transition-all duration-300 ease-[ease] hover:border-[black] hover:shadow-[0px_2px_4px_0px_#00000045]"
            onClick={() => {
              setOpen(!open);
            }}
          >
            <Icon path={mdiTrayArrowUp} size={0.9} />
          </a>
        </div>
        <div className="flex items-center mb-5">
          <div className="w-60 relative px-1 mr-1">
            <SelectField
              required={true}
              id="08"
              placeholder="filter by"
              defaultOptions={timeOptions}
              value={filterByTime}
              onChange={(val) => {
                handleTimeFilter(val);
              }}
            />
          </div>
          {filterByTime?.value === "month" && (
            <div className="max-w-[200px] mr-2">
              <DateField
                dateFormat="YYYY-MM"
                placeholder="Select Month"
                className="bg-white rounded-md min-h-[38px]"
                timeFormat={false}
                onChange={handleFilterByMonth}
                value={filterbyMonth}
              />
            </div>
          )}
          {filterByTime?.value === "year" && (
            <div className="max-w-[200px] mr-1">
              <DateField
                dateFormat="YYYY"
                placeholder="Select Year"
                className="bg-white rounded-md min-h-[38px]"
                timeFormat={false}
                onChange={handleFilterByYear}
                value={filterbyYear}
              />
            </div>
          )}

          <div className="w-60 relative px-1">
            <SelectField
              required={true}
              id="08"
              placeholder="event status"
              defaultOptions={eventStatusOptions}
              onChange={(val) => {
                handleFilterByStatus(val);
              }}
              value={filterByStatus}
            />
          </div>
        </div>
        {upcomingEvents.length > 0 ? (
          <div className="bg-white w-full px-10 py-[30px] rounded-[10px] shadow-[1px_-1px_10px_0px_rgba(0,0,0,0.10)]">
            <div className="flex flex-wrap -m-2.5 overflow-hidden">
              {upcomingEvents.map((upcomingEvent, index) => {
                return (
                  <div key={"event" + index} className="w-1/4 h-full p-2.5">
                    <div className="rounded-[10px] bg-[#ffa8a8] overflow-hidden h-full relative">
                      <img
                        src={`${s3Url}${upcomingEvent.imageUrl}`}
                        alt={upcomingEvent.title}
                        className="w-full h-full object-cover"
                      />
                      <div className="absolute w-full h-full flex items-center justify-center gap-x-3 bg-[rgba(0,0,0,0.60)] opacity-0 inset-0 hover:opacity-100 transition-all duration-300 ease-[ease]">
                        <a className="w-10 h-10 bg-white flex items-center justify-center p-1.5 rounded-[50%] transition-all duration-300 ease-[ease] hover:scale-[1.06]">
                          <button
                            onClick={() => {
                              setSelectedEvent(upcomingEvent);
                              setOpen(true);
                            }}
                          >
                            <Icon path={mdiPencil} size={1} />
                          </button>
                        </a>
                        <a className="w-10 h-10 bg-white flex items-center justify-center p-1.5 rounded-[50%] transition-all duration-300 ease-[ease] hover:scale-[1.06]">
                          <button
                            onClick={() =>
                              removeUpcomingEvent(upcomingEvent._id)
                            }
                          >
                            <Icon path={mdiTrashCan} size={1} />
                          </button>
                        </a>
                        <a className="w-10 h-10 bg-white flex items-center justify-center p-1.5 rounded-[50%] transition-all duration-300 ease-[ease] hover:scale-[1.06]">
                          <button
                            onClick={() => {
                              setImgURL(upcomingEvent.imageUrl);
                              handleOpenImgPreview();
                            }}
                          >
                            <Icon path={mdiEye} size={1} />
                          </button>
                        </a>
                      </div>
                    </div>
                    <div className="m-1 mt-2">
                      <div className="text-[14px]">{upcomingEvent.title}</div>
                      <div className="text-[#A4A4A4] text-[11px]">
                        Date:{" "}
                        <span>
                          {moment(upcomingEvent.effectiveStart).format(
                            "DD-MM-YYYY"
                          )}{" "}
                          to{" "}
                          {moment(upcomingEvent.effectiveEnd).format(
                            "DD-MM-YYYY"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <EventBannerUpload
        open={open}
        setOpen={setOpen}
        uploadEventBanner={uploadEventBanner}
        selectedEvent={selectedEvent}
        setSelectedEvent={setSelectedEvent}
      />

      <ImgPreviewModal
        previewImg={`${s3Url}${imgURL}`}
        closeModal={handleClosePreview}
        show={openImgPreviewModal}
      />
    </>
  );
};

export default SabhaPosts;
