import React from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const BookContent = ({ contentDetail, setContentDetail, updateContentDetail, createContentDetail }) => {

    const modules = {
        toolbar: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ font: [] }],
          [{ size: ['small', false, 'large', 'huge'] }],
          [{ 'direction': 'rtl' }],
          ["bold", "italic", "underline", "strike", "blockquote", "code-block"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link", "image", "video", "formula"],
          [{ 'color': [] }, { 'background': [] }],
          [{ 'align': [] }],
    
          ['clean'] 
        ],
      }

    return (
        <form className="">
            <div className="">
                <div className="">
                    <span className="text-primary">Top Text</span>
                    <div className="mb-5">
                        <ReactQuill
                            theme="snow"
                            value={contentDetail?.top_content || ""}
                            onChange={(e) => setContentDetail({ ...contentDetail, top_content: e })}
                            modules={modules}
                            className=""
                        />

                    </div>
                </div>
                <div className="">
                    <span className="text-primary">Middle Text</span>
                    <div className="mb-5">
                        <ReactQuill
                            theme="snow"
                            value={contentDetail?.middle_content || ""}
                            onChange={(e) => setContentDetail({ ...contentDetail, middle_content: e })}
                            modules={modules}
                            className=""
                        />
                    </div>
                </div>
                <div className="">
                    <span className="text-primary">Bottom Text</span>
                    <div className="mb-5">
                        <ReactQuill
                            theme="snow"
                            value={contentDetail?.bottom_content || ""}
                            onChange={(e) => setContentDetail({ ...contentDetail, bottom_content: e })}
                            modules={modules}
                            className=""
                        />
                    </div>
                </div>
                <div className="mt-8 text-right">
                    {false && (
                        <button
                            type="button"
                            className="inline-flex self-end mb-1 items-center h-9 w-5 bg-white min-w-[90px] justify-center text-red-600 border border-red-600 text-sm px-2 py-2 rounded-lg"
                        // onClick={() => emptyForm()}
                        >
                            Cancel
                        </button>
                    )}
                    <button
                        type="button"
                        className="inline-flex self-end ml-2 -mt-1.5  items-center h-9 w-5 bg-primary min-w-[90px] justify-center text-white text-sm px-2 py-2 rounded-lg"
                        onClick={() => contentDetail?._id ? updateContentDetail() : createContentDetail()}
                    >
                        {contentDetail?._id ? "Update" : "Add"}
                    </button>
                </div>
            </div>
        </form>
    )

}

export default BookContent;