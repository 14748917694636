import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { useLocation } from "react-router-dom";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { Button } from "@material-tailwind/react";
import BookListIndexForm from "./BookListIndexForm";
import { useReactNavigator } from "../../utils/reactNavigator";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { motion } from "framer-motion";
import { changeBookIndexOrder, createBookContent, createBookIndex, deleteBookIndex, getBookContent, getBookIndex, updateBookContent, updateBookIndexTitle } from "./actionCreator";
import AlertModal from "../Common/CustomConfirmationModal/CustomConfirmationModal";
import BookContent from "./BookContent";
import { toastr } from "../../services/common.service";

const DraggableRow = ({ row, reorderBook }) => {
    const [, dropRef] = useDrop({
        accept: "row",
        drop: (draggedRow) => reorderBook(draggedRow.index, row.index),
    });

    const [{ isDragging }, dragRef, previewRef] = useDrag({
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        item: () => row,
        type: "row",
    });

    return (
        <motion.tr
            ref={previewRef}
            initial={{ opacity: 1 }}
            animate={{ opacity: isDragging ? 0.5 : 1 }}
            exit={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
        >
            <td ref={dropRef} className="text-start w-[50px]">
                <button ref={dragRef} className="cursor-grab">
                    <Icon icon="grommet-icons:drag" className="text-xl" />
                </button>
            </td>
            {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="p-2">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
            ))}
        </motion.tr>
    );
};

const BookListIndex = (props) => {
    
    const columnHelper = createColumnHelper();

    const { navigate } = useReactNavigator();
    const location = useLocation();

    let formData = location.state;

    const [indexDetail, setIndexDetail] = useState(formData.data);
    const [bookDetail, setBookDetail] = useState(formData.bookDetail);
    const [data, setData] = useState([]);
    const [openMemberForm, setOpenMemberForm] = useState(false);
    const [currentIndexDetail, setCurrentIndexDetail] = useState("");
    const [showConfirmationModel, setShowConfirmationModel] = useState(false);
    const [currentIndexId, setCurrentIndexId] = useState("");
    const [contentDetail, setContentDetail] = useState({});
    const [contentIndex, setContentIndex] = useState(formData.data._id);
    const [showContentForm, setShowContentForm] = useState(false);
    const [showIndexList, setShowIndexList] = useState(false);
    const [showCreateSubIndexButton, setShowCreateSubIndexButton] = useState(false);

    const updateContentDetail = async () => {
        const data = contentDetail;
        data.indexId = contentIndex;
        data.bookId = formData.bookDetail._id;
        await updateBookContent(contentDetail._id, data);
    }

    const createContentDetail = async () => {
        const data = contentDetail;
        data.indexId = formData.data._id;
        data.bookId = formData.data.bookId;
        const updateData = await createBookContent(data);
        setContentDetail(updateData.data);
        setShowCreateSubIndexButton(false);
    }

    const reorderBook = (draggedRowIndex, targetRowIndex) => {
        data.splice(targetRowIndex, 0, data.splice(draggedRowIndex, 1)[0]);
        setData([...data]);
        const indexIds = data.map((d) => d._id);
        changeBookIndexOrder({ indexIds, indexId: indexDetail._id })
            .then(() => {
                getIndexes(indexDetail._id);
            })
            .catch((err) => {
                toastr.show("Something went wrong", "error");
            });
    };

    const getIndexes = async (indexId) => {
        const bookIndexes = await getBookIndex(indexId, true);
        if (bookIndexes.data.length > 0) {
            setShowContentForm(false);
            setShowIndexList(true);
        } else {
            setShowContentForm(true);
            setShowIndexList(false);
        }
        setShowCreateSubIndexButton(true);
        setData(bookIndexes.data);
    }

    const getContent = async (indexId) => {
        const bookIndexes = await getBookContent(indexId);
        setContentDetail(bookIndexes.data);
        setShowContentForm(true);
        setShowIndexList(false);
        setShowCreateSubIndexButton(false);
    }

    const updateIndexTitle = async (id, title) => {
        const data = {
            title: title
        }
        await updateBookIndexTitle(id, data);
        getIndexes(indexDetail._id);
        setOpenMemberForm(!openMemberForm);
    }

    const createIndexTitle = async (id, title) => {
        const data = {
            bookId: id,
            title: title,
            parentIndex: indexDetail._id
        }
        await createBookIndex(data);
        getIndexes(indexDetail._id);
        setOpenMemberForm(!openMemberForm);
    }

    const showDeleteModel = async (id) => {
        setShowConfirmationModel(!showConfirmationModel);
        setCurrentIndexId(id);
    }

    const deleteIndex = async (status) => {
        if (status) {
            await deleteBookIndex(currentIndexId);
            getIndexes(indexDetail._id);
        } 
        setShowConfirmationModel(!showConfirmationModel);
        
    }
    
    useEffect(() => {
        if (indexDetail?.hasContent) {
            getContent(indexDetail._id);
        } else {
            getIndexes(indexDetail._id);
        }
        
    }, [indexDetail])

    const openMemberFormLink = (id) => {
        if (id) {
            const currentIndex = data.find((item) => item._id + "" === id + "");
            setCurrentIndexDetail(currentIndex);
        }
        setOpenMemberForm(!openMemberForm);
    };

    const columns = [
        // columnHelper.accessor("title", {
        //     cell: (info) => info.getValue(),
        //     header: () => <span>Title</span>,
        // }),
        columnHelper.accessor("title", {
            cell: (info) => info.getValue(),
            header: () => <span>Title</span>,
        }),
        columnHelper.display({
            header: () => <span className="text-center">Actions</span>,
            id: "actions",
            cell: (props) => {
                return (
                    <span className="flex items-center justify-center">
                        <span className="w-9 h-9 border border-black900 rounded-xl transition-all duration-150 flex justify-center items-center mr-2 cursor-pointer group text-black900">
                            <button>
                                <Icon
                                    icon="ic:baseline-edit"
                                    className="text-2xl transition-all duration-150 text-black900"
                                  onClick={() => openMemberFormLink(props.row.original?._id)}
                                />
                            </button>
                        </span>
                        <span className="w-9 h-9 border border-black900 rounded-xl transition-all duration-150 flex justify-center items-center mr-2 cursor-pointer group hover:text-black900">
                            <button>
                                <Icon
                                    icon="material-symbols:delete"
                                    className="transition-all duration-150 text-2xl text-black900"
                                  onClick={() => showDeleteModel(props.row.original?._id)}
                                />
                            </button>
                        </span>
                        <span className="w-9 h-9 border border-black900 rounded-xl transition-all duration-150 flex justify-center items-center mr-2 cursor-pointer group hover:text-black900">
                            <button
                                onClick={() => {
                                    navigate(`${props.row.original?._id}`, {
                                        data: props.row.original,
                                        bookDetail: formData.bookDetail,
                                        parentIndex: indexDetail
                                    });
                                }}
                            >
                                <Icon
                                    icon="ion:enter"
                                    className="text-2xl transition-all duration-150 text-black900"
                                />
                            </button>
                        </span>
                    </span>
                );
            },
        }),
    ];

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getRowId: (row) => row.id,
    });

    return (
        <div>
            <div className="h-[calc(100vh_-_55px)] p-5 pb-5 bg-[#F8F8F8] overflow-auto">
                <div className="flex items-center justify-between pb-[15px] mb-[20px] border-b-[#D7D7D7] border-b border-solid">
                    <div className="flex items-center">
                        <Icon
                            icon="icon-park-solid:left-c"
                            className="text-3xl mr-3 cursor-pointer"
                            onClick={() => navigate(`/book/list/${bookDetail?._id}`, { data: bookDetail })}
                        />
                        <div className="text-[25px] text-[#212121]">
                            {formData.data.title}
                        </div>
                    </div>
                    {showCreateSubIndexButton &&
                        <Button
                            color="blue"
                            className="flex items-center min-w-[150px]"
                            onClick={openMemberFormLink}
                        >
                            <Icon icon="ic:baseline-plus" className="mr-1 text-2xl" />
                            Create Book Sub Index
                        </Button>}
                </div>

                {showContentForm && <BookContent
                    contentDetail={contentDetail}
                    setContentDetail={setContentDetail}
                    updateContentDetail={updateContentDetail}
                    createContentDetail={createContentDetail}
                />}

                {showIndexList &&
                    <div className="bg-white w-full px-6 py-[30px] overflow-hidden rounded-[10px] shadow-[1px_-1px_10px_0px_rgba(0,0,0,0.10)]">
                        <div className="overflow-auto">
                            <DndProvider backend={HTML5Backend}>
                                <table className="w-full">
                                    <thead>
                                        {table.getHeaderGroups().map((headerGroup) => {
                                            return (
                                                <tr className="border-b border-stone-200 text-sm bg-[#F2F2F2] text-[#535353]">
                                                    <th className="text-left font-semibold p-2 min-w-[50px]"></th>
                                                    {headerGroup.headers.map((header) => {
                                                        return (
                                                            <th
                                                                key={header.id}
                                                                colSpan={header.colSpan}
                                                                className="text-left font-semibold p-2"
                                                            >
                                                                {header.isPlaceholder ? null : (
                                                                    <div
                                                                        {...{
                                                                            className: header.column.getCanSort()
                                                                                ? "cursor-pointer select-none"
                                                                                : "last:text-center",
                                                                        }}
                                                                    >
                                                                        {flexRender(
                                                                            header.column.columnDef.header,
                                                                            header.getContext()
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </th>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })}
                                    </thead>
                                    <tbody className="last:!border-b-0">
                                        {table.getRowModel().rows.map((row) => (
                                            <DraggableRow
                                                key={row.id}
                                                row={row}
                                                reorderBook={reorderBook}
                                            />
                                        ))}
                                    </tbody>
                                </table>
                            </DndProvider>
                        </div>
                    </div>}
            </div>

            {openMemberForm && (
                <BookListIndexForm
                    bookDetail={bookDetail}
                    formData={formData}
                    openMemberForm={openMemberForm}
                    setOpenMemberForm={setOpenMemberForm}
                    createIndexTitle={createIndexTitle}
                    updateIndexTitle={updateIndexTitle}
                    currentIndexDetail={currentIndexDetail}
                />
            )}
            <AlertModal
                open={showConfirmationModel}
                handleCloseModal={deleteIndex}
                messageText="Are you sure you want to delete this Index?"  
                submitBtnText="Confirm"
                buttonType="primary"
                icon="icon-park:go-end"
            />
            
        </div>
    )

}

export default BookListIndex;