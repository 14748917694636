import React, { useEffect, useState } from "react";
import lodash from "lodash";
import moment from "moment-timezone";

import { useDispatch } from "react-redux";
import Icon from "@mdi/react";
import {
  mdiSquareEditOutline,
  mdiDelete,
  mdiCalendarCheck,
  mdiRefresh,
  mdiChevronRightCircle,
  mdiChevronLeftCircle,
} from "@mdi/js";
import DeleteModal from "../../Common/DeleteModal/DeleteModal";
import {
  completeAttendence,
  countAttendence,
  createFormSabha,
  deleteSabha,
  getForm,
  startAttendence,
  updateFormSabha,
  getFormSabha
} from "./actionCreator";
import { ACTION_TYPES } from "../../../redux/constants";

import CreatableMultiselectMain from "../../Common/Input/CreatableMultiselectMain";
import { toastr } from "../../../services/common.service";
import { filterSabhaListOnTypeAndDate } from "../memberlist/utility";
import ReactPaginate from "react-paginate";
import AlertModal from "../../Common/CustomConfirmationModal/CustomConfirmationModal";
import DatePickerField from "../../Common/Rsuite-Datepicker";

const CreateSabhaForm = () => {
  const dispatch = useDispatch();

  const [isEdit, setIsEdit] = useState(false);
  const [form, setForm] = useState({});
  const [dateOfSabha, setDateOfSabha] = useState("");
  const [startTimeOfSabha, setStartTimeOfSabha] = useState("");
  const [endTimeOfSabha, setEndTimeOfSabha] = useState("");
  const [currentSabhaId, setCurrentSabhaId] = useState("");
  const [sabhaFilter, setSabhaFilter] = useState({});
  const [formSabhaList, setFormSabhaList] = useState([])
  const [filterSabhaList, setFilterSabhaList] = useState([]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [
    showCompleteSabhaConfirmationModal,
    setShowCompleteSabhaConfirmationModal,
  ] = useState(false);
  const [sabhaStart, setSabhaStart] = useState(false);
  const [itemOffset, setItemOffset] = useState(0);

  const [formOptions, setFormOptions] = useState([]);

  const itemsPerPage = 5; // Sabha pagination
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = filterSabhaList?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(filterSabhaList?.length / itemsPerPage);

  const handlePageClick = (event) => {
    if (filterSabhaList.length > 0) {
      const newOffset = (event.selected * itemsPerPage) % filterSabhaList?.length;
      setItemOffset(newOffset);
    }
  };

  const fetchForm = async () => {
    const response = await getForm({ filter: "active" });
    if(response?.length){
      const forms = response.map((d) => ({label: d.title,  value: d._id}))
      setFormOptions(forms)
    }
  };

  const fetchFormSabha = async () => {
    const response = await getFormSabha();
    setFormSabhaList(response.data || []);
  };

  useEffect(() => {
    fetchForm();
    fetchFormSabha();
    handlePageClick({ selected: 0 });
  }, []);

  useEffect(() => {
    if (formSabhaList && formSabhaList.length > 0) {
      setFilterSabhaList(formSabhaList);
      handlePageClick({ selected: 0 });
    }
  }, [formSabhaList]);

  const editSabha = (id) => {
    setIsEdit(true);
    const currentSabha = formSabhaList.find((sabha) => sabha._id + "" === id + "");
    const sabhaTypes = formOptions.filter((sb) =>
      currentSabha.formId === (sb.value + "")
    );
    setSabhaStart(currentSabha?.isStarted);
    if(sabhaTypes?.length) setForm(sabhaTypes[0]);
    setDateOfSabha(new Date(currentSabha.date));
    setStartTimeOfSabha(currentSabha?.startTime);
    setEndTimeOfSabha(currentSabha?.endTime);
    setCurrentSabhaId(currentSabha._id);
  };

  const handleCloseDeleteModal = async (status) => {
    if (status) {
      const updatedData = await deleteSabha({ _id: currentSabhaId });
      if (updatedData?.data) {
        toastr.show("Deleted!!", "success");
        setFormSabhaList((prevFormSabhaList) => {
          const newList = prevFormSabhaList.filter((f) => f._id + "" !== currentSabhaId);
          return newList;
        });
      } else {
        toastr.show("Something went wrong", "error");
      }
    }
    setShowDeleteModal(false);
    setCurrentSabhaId(null);
  };

  const handleConfirmationDeleteModal = async (status) => {
    if (status) {
      await startSabhaAttendence(currentSabhaId);
    }
    setShowConfirmationModal(false);
    setCurrentSabhaId(null);
  };

  const handleCompleteSabhaConfirmationDeleteModal = async (status) => {
    if (status) {
      try {
        const data = await completeAttendence(currentSabhaId);
        if (data?.data?._id) {
          const newList = formSabhaList.map((f) => {
            if (f._id + "" === data.data._id + "") {
              return { ...f, ...data.data };
            }
            return f;
          });
          setFormSabhaList(newList)
        }
      } catch (err) {
        toastr.show(err.message, "error");
      }
    }
    setShowCompleteSabhaConfirmationModal(false);
    setCurrentSabhaId(null);
  };

  const handleOpenDeleteModal = (id) => {
    setCurrentSabhaId(id);
    setShowDeleteModal(!showDeleteModal);
  };

  const handleOpenConfirmationModal = (id) => {
    setCurrentSabhaId(id);
    setShowConfirmationModal(!showConfirmationModal);
  };

  const handleOpenCompleteSabhaConfirmationModal = (id) => {
    setCurrentSabhaId(id);
    setShowCompleteSabhaConfirmationModal(!showCompleteSabhaConfirmationModal);
  };

  const selectSabhaType = (sabhaForm) => {
    if(!sabhaForm) {
      setForm({});
      return;
    }
    setForm({ value: sabhaForm.value, label: sabhaForm.label });
  };

  const createNewSabha = async () => {
    if (Object.keys(form).length === 0)
      toastr.show("Please select Form", "error");
    else if (!dateOfSabha) toastr.show("Please select sabha date", "error");
    else if (!startTimeOfSabha)
      toastr.show("Please select sabha start time", "error");
    else {
      const formId = form.value;
      const diffDays = moment(dateOfSabha).diff(
        moment().startOf("day"),
        "days"
      );
      const sabhaPayload = {
        formId,
        date: moment(dateOfSabha).toISOString(),
        startTime: moment(startTimeOfSabha).add(diffDays, "days").toISOString(),
        endTime: moment(endTimeOfSabha).add(diffDays, "days").toISOString(),
      };
      let newSabha = {};
      if (isEdit) {
        sabhaPayload._id = currentSabhaId;
        newSabha = await updateFormSabha(sabhaPayload);
      } else {
        newSabha = await createFormSabha(sabhaPayload);
      }

      if (newSabha?.data) {
        toastr.show("Save!!", "success");
        let newList;
        if (isEdit) {
          newList = formSabhaList.map((f) => {
            if (f._id + "" === newSabha.data._id + "") {
              return { ...f, ...newSabha.data };
            }
            return f;
          });
        } else {
          newList = [...formSabhaList, newSabha.data];
        }
        setFormSabhaList(newList)
        emptyForm();
      }
    }
  };

  const startSabhaAttendence = async (sabhaId) => {
    try {
      if (!sabhaId) return;

      const selectedSabha = formSabhaList.find((d) => d._id === sabhaId);
      if (!selectedSabha) return;

      if (moment().diff(selectedSabha.startTime) < 0) {
        toastr.show("Sabha cannot be started before start time.", "error");
        return;
      }
      if (moment().diff(selectedSabha.endTime) > 0) {
        toastr.show("Sabha cannot be started after end time.", "error");
        return;
      }

      const sabhaPayload = { formEventId: sabhaId };
      const updatedSabha = await startAttendence(sabhaPayload);
      if (updatedSabha?.data) {
        const newList = formSabhaList.map((f) => {
          if (f._id + "" === updatedSabha.data._id + "") {
            return { ...f, ...updatedSabha.data };
          }
          return f;
        });
        setFormSabhaList(newList)
      }
      toastr.show("Success !!", "success");
    } catch (err) {
      toastr.show("Failed !!", "error");
    }
  };

  const countSabhaAttendence = async (sabhaId) => {
    try {
      if (!sabhaId) return;

      const selectedSabha = formSabhaList.find((d) => d._id === sabhaId);
      if (!selectedSabha) return;

      const sabhaPayload = { formEventId: sabhaId };
      const updatedSabha = await countAttendence(sabhaPayload);
      if (updatedSabha?.data) {
        const newList = formSabhaList.map((f) => {
          if (f._id + "" === updatedSabha.data._id + "") {
            return { ...f, ...updatedSabha.data };
          }
          return f;
        });
        setFormSabhaList(newList)
      }
      toastr.show("Success !!", "success");
    } catch (err) {
      toastr.show("Failed !!", "error");
    }
  };

  const emptyForm = () => {
    setForm({});
    setDateOfSabha("");
    setStartTimeOfSabha("");
    setEndTimeOfSabha("");
    setIsEdit(false);
    setCurrentSabhaId("");
  };

  const filterData = (key, value) => {
    const selectedFilter = lodash.cloneDeep(sabhaFilter);
    selectedFilter[key] = value;
    setSabhaFilter(selectedFilter);
    filterSabha(selectedFilter);
  };

  const filterSabha = (_sabhaFilter) => {
    const _sabhaList = lodash.cloneDeep(formSabhaList);
    const _filterSabhaList = filterSabhaListOnTypeAndDate({
      _sabhaList,
      ..._sabhaFilter,
    });
    setFilterSabhaList(_filterSabhaList);
  };

  return (
    <>
      <form className="border border-primary bg-white rounded-lg p-6 mt-5 shadow-[rgba(0,0,0,0.1)_0px_10px_50px]">
        <div className="flex items-center justify-between">
          <div className="flex gap-5">
            <div>
              <CreatableMultiselectMain
                label="Forms"
                required={true}
                isClearable={false}
                isDisabled={sabhaStart}
                isMulti={false}
                placeholder="Enter Forms"
                options={formOptions}
                value={form}
                onChange={(e) => selectSabhaType(e)}
              />
            </div>
            <div className="">
              <label className="text-sm text-gray300 font-semibold mb-2 inline-block">
                Sabha Date
                <span className="text-red400">*</span>
              </label>
              <DatePickerField
                placeholder="Select Date"
                id="02"
                readOnly={sabhaStart}
                value={dateOfSabha}
                onChange={(date) => setDateOfSabha(date)}
              />
            </div>
            {/* <TimePicker
                label="Start Time"
                required={true}
                disabled={sabhaStart}
                startDate={dateOfSabha}
                placeholder="Select Time"
                value={
                  startTimeOfSabha
                    ? moment(startTimeOfSabha).format("hh:mm A")
                    : ""
                }
                onChange={(date) => setStartTimeOfSabha(date)}
              /> */}
            <div>
              <div className="text-sm text-gray300 font-semibold mb-2">
                Start Time
                <span className="text-red400">*</span>
              </div>
              <DatePickerField
                readOnly={sabhaStart}
                onTrap={false}
                format="HH:mm"
                placeholder="Select Time"
                value={startTimeOfSabha ? new Date(startTimeOfSabha) : ""}
                onChange={(date) => setStartTimeOfSabha(date)}
              />
            </div>
            {/* <div className="flex gap-x-5">
              <TimePicker
                label="End Time"
                required={true}
                startDate={dateOfSabha}
                placeholder="Select Time"
                value={
                  endTimeOfSabha ? moment(endTimeOfSabha).format("hh:mm A") : ""
                }
                onChange={(date) => setEndTimeOfSabha(date)}
              />
            </div> */}
            <div>
              <div className="text-sm text-gray300 font-semibold mb-2">
                End Time
                <span className="text-red400">*</span>
              </div>
              <DatePickerField
                onTrap={false}
                format="HH:mm"
                placeholder="Select Time"
                value={endTimeOfSabha ? new Date(endTimeOfSabha) : ""}
                onChange={(date) => setEndTimeOfSabha(date)}
              />
            </div>
          </div>
          <div className="mt-8 text-right">
            {isEdit && (
              <button
                type="button"
                className="inline-flex self-end mb-1 items-center h-9 w-5 bg-white min-w-[90px] justify-center text-red-600 border border-red-600 text-sm px-2 py-2 rounded-lg"
                onClick={() => emptyForm()}
              >
                Cancel
              </button>
            )}
            <button
              type="button"
              className="inline-flex self-end ml-2 -mt-1.5  items-center h-9 w-5 bg-primary min-w-[90px] justify-center text-white text-sm px-2 py-2 rounded-lg"
              onClick={() => createNewSabha()}
            >
              {isEdit ? "Update" : "Save"}
            </button>
          </div>
        </div>
      </form>
      <div className=" mt-10">
        <div className="mb-3 flex items-center">
          <div className="flex gap-x-5 mr-5">
            <CreatableMultiselectMain
              isClearable={true}
              isMulti={true}
              placeholder="Enter Forms"
              options={formOptions}
              value={sabhaFilter?.formId}
              onChange={(e) => filterData("formId", e)}
            />
          </div>
          <div className="flex gap-x-5 mr-5">
            <DatePickerField
              cleanable={true}
              placeholder="Start Date"
              value={sabhaFilter?.startDateOfSabha}
              onChange={(e) => filterData("startDateOfSabha", e)}
            />
          </div>
          <div className="flex gap-x-5 mr-5">
            <DatePickerField
              cleanable={true}
              placeholder="End Date"
              value={sabhaFilter?.endDateOfSabha}
              onChange={(e) => filterData("endDateOfSabha", e)}
            />
          </div>
        </div>
        <div className="border border-stone-200 rounded-lg overflow-auto">
          <table className="w-full member-table">
            <thead>
              <tr className="border-b border-stone-200 text-sm bg-[#F2F2F2] text-[#535353]">
                <th className="text-left px-2 py-4">No.</th>
                <th className="px-2 py-4">Sabha</th>
                <th className="px-2 py-4">Sabha Date</th>
                <th className="px-2 py-4">Start Time</th>
                <th className="px-2 py-4">End Time</th>
                <th className="px-2 py-4">Status</th>
                <th className="px-2 py-4">Male</th>
                <th className="px-2 py-4">Female</th>
                <th className="px-2 py-4">Total Present</th>
                <th className="px-2 py-4">Actions</th>
              </tr>
            </thead>
            <tbody className="last:!border-b-0">
              {currentItems.map((sabha, sabhaIndex) => {
                if (!sabha?.isDeleted) {
                  return (
                    <tr
                      key={"sabha" + (itemOffset + sabhaIndex)}
                      className="border-b border-stone-200 text-sm"
                    >
                      <td className="px-2 py-4">
                        {itemOffset + sabhaIndex + 1}
                      </td>
                      <td className="text-center px-2 py-4">
                        {formOptions.filter((sb) =>
                          sabha.formId === sb.value + ""
                        )
                          .map((sb) => sb.label)
                          .join(", ")}
                      </td>
                      <td className="text-center px-2 py-4">
                        {moment(sabha.date).format("DD/MM/YYYY")}
                      </td>
                      <td className="text-center px-2 py-4">
                        {moment(sabha.startTime).format("h:mm A")}
                      </td>
                      <td className="text-center px-2 py-4">
                        {sabha?.endTime
                          ? moment(sabha.endTime).format("h:mm A")
                          : ""}
                      </td>
                      <td className="text-center px-2 py-4">
                        <span className="mr-2">
                          {sabha.isStarted ? (
                            moment().diff(sabha.endTime) > 0 ? (
                              <span className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
                                Done
                              </span>
                            ) : (
                              <span className="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">
                                In Progress
                              </span>
                            )
                          ) : moment().diff(sabha.endTime) > 0 ? (
                            <span className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
                              Ended Without Start
                            </span>
                          ) : (
                            <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
                              Pending
                            </span>
                          )}
                        </span>
                      </td>
                      <td className="text-center px-2 py-4">
                        <span className="flex item-center ">
                          <span className="text-base">
                            {sabha.totalMaleAttendence || 0}
                          </span>
                        </span>
                      </td>
                      <td className="text-center px-2 py-4">
                        <span className="flex item-center ">
                          <span className="text-base">
                            {sabha.totalFemaleAttendence || 0}
                          </span>
                        </span>
                      </td>
                      <td className="text-center px-2 py-4">
                        <span className="flex item-center ">
                          <Icon
                            path={mdiRefresh}
                            size={1}
                            className="inline mx-0.5 text-[#000000] cursor-pointer mr-2"
                            onClick={() => countSabhaAttendence(sabha._id)}
                          />
                          <span className="text-base">
                            {sabha.totalAttendence || 0}
                          </span>
                        </span>
                      </td>
                      <td className="text-center px-2 py-4">
                        {!sabha?.isStarted &&
                          moment().diff(sabha.endTime) < 0 && (
                            <>
                              <Icon
                                path={mdiCalendarCheck}
                                size={1}
                                className="inline mx-1 text-[#000000] cursor-pointer"
                                onClick={() =>
                                  handleOpenConfirmationModal(sabha._id)
                                }
                              />
                            </>
                          )}
                        {sabha?.isStarted && !sabha?.isComplete && (
                          <>
                            <Icon
                              path={mdiCalendarCheck}
                              size={1}
                              className="inline mx-1 text-[#000000] cursor-pointer"
                              onClick={() =>
                                handleOpenCompleteSabhaConfirmationModal(
                                  sabha._id
                                )
                              }
                            />
                          </>
                        )}
                        {moment().diff(sabha.endTime) < 0 && (
                          <>
                            <Icon
                              path={mdiSquareEditOutline}
                              size={1}
                              className="inline mx-0.5 text-[#000000] cursor-pointer"
                              onClick={() => editSabha(sabha._id)}
                            />
                          </>
                        )}
                        {!sabha?.isStarted && (
                          <>
                            <Icon
                              path={mdiDelete}
                              size={1}
                              className="inline mx-0.5 text-[#000000] cursor-pointer"
                              onClick={() => handleOpenDeleteModal(sabha._id)}
                            />
                          </>
                        )}
                      </td>
                    </tr>
                  );
                }
              })}
              {!formSabhaList?.length && (
                <tr>
                  <td colSpan={7}>No Records Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <DeleteModal
        open={showDeleteModal}
        handleCloseModal={handleCloseDeleteModal}
      />
      <AlertModal
        open={showConfirmationModal}
        handleCloseModal={handleConfirmationDeleteModal}
        messageText="Are you sure you want to start sabha?"
        submitBtnText="Confirm"
        buttonType="primary"
        icon="icon-park:go-end"
      />

      <AlertModal
        open={showCompleteSabhaConfirmationModal}
        handleCloseModal={handleCompleteSabhaConfirmationDeleteModal}
        messageText="Are you sure you want to complete sabha?"
        submitBtnText="Confirm"
        buttonType="primary"
        icon="icon-park:go-end"
      />

      <ReactPaginate
        breakLabel="..."
        nextLabel={<Icon path={mdiChevronRightCircle} size={1} />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        marginPagesDisplayed={3}
        pageCount={pageCount}
        previousLabel={<Icon path={mdiChevronLeftCircle} size={1} />}
        renderOnZeroPageCount={null}
        containerClassName="flex justify-end my-5"
        pageClassName="mx-3 text-gray100"
        activeClassName="!text-black900 font-semibold"
        disabledClassName="text-gray400"
        previousClassName="mr-2"
        nextClassName="ml-2"
      />
    </>
  );
};

export default CreateSabhaForm;
