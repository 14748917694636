import React from "react";
import { useRoutes } from "react-router-dom";
import { BookList } from "../../../Components";
import BookListIndex  from "../../../Components/booklist/BookListIndex";
import BookListSubIndex from "../../../Components/booklist/BookListSubIndex";
import BookListText from "../../../Components/booklist/BookListText";
export default function BookListRouter() {
  const BookListRoute = [
    {
      path: "/list/*",
      element: (
        <>
          <BookList />
        </>
      ),
    },
    {
        path: "/list/:id/*",
        element: (
          <>
            <BookListIndex />
          </>
        ),
      },
      {
        path: "/list/:id/:id/*",
        element: (
          <>
            <BookListSubIndex />
          </>
        ),
      },
      {
        path: "/list/:id/:id/:id/*",
        element: (
          <>
            <BookListText />
          </>
        ),
      },
  ];
  const BookListRouter = useRoutes(BookListRoute);
  return BookListRouter;
}
