import { Typography } from "@material-tailwind/react";
import React from "react";

export default class ComponentToPDF extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { headers, data, innerRef } = this.props;

    const newHeaders = headers.filter(header => !['kishorAttd', 'yuvaAttd', 'atmiyaAttd'].includes(header));

    const mantraJapRow = () => {
      return data?.map((item, index) => {
        return (
          <tr key={item?.id} className='text-center font-semibold p-2'>
            {newHeaders.map((header) => {
              if (header === "profileImgUrl") {
                return (
                  <td key={header} className="p-2 border-b border-blue-gray-50">
                    <img
                      className="min-w-[48px] max-w-[48px] h-12 rounded-full mr-3 object-cover"
                      alt="profile"
                      src={
                        item?.['Profile Image']
                          ? `${item['Profile Image']}`
                          : item?.["gender"] === 'female'
                          ? "/assets/image/female-avatar.jpg"
                          : "/assets/image/profilepicture.png"
                      }
                    />
                  </td>
                );
              } else {
                return (
                  <td key={header} className="p-2 border-b border-blue-gray-50">
                    <Typography
                      variant="medium"
                      color="blue-gray"
                      className="font-medium text-stone-400 text-sm font-[500] capitalize"
                    >
                      {item[header]}
                    </Typography>
                  </td>
                );
              }
            })}
          </tr>
        );
      });
    }

    return (
      <div className="p-1" ref={innerRef}>
        {/* <h2 style={{color: "orange"}}> શ્રી સ્વામિનારાયણ મહામંત્ર લેખન સંખ્યા </h2> */}
        <table className="table-auto">
          {/* <caption class="caption-top">
            Date: 
          </caption> */}
          <thead>
            {newHeaders.map(element => {
              return (<th class="border-b border-blue-gray-100 bg-blue-gray-50 p-4">{element === "profileImgUrl" ? "Profile" : element}</th>);
            })}
          </thead>
          <tbody>
            { mantraJapRow() }
          </tbody>
        </table>
      </div>
    );
  }
}
  