import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { useLocation } from "react-router-dom";
import { useReactNavigator } from "../../utils/reactNavigator";
import AreaField from "../Common/Input/AreaField";
import { createBookContent, getBookContent, updateBookContent } from "./actionCreator";
import BookContent from "./BookContent";

const BookListText = () => {

    const { navigate } = useReactNavigator();
    const location = useLocation();
    const formData = location.state;

    const [openContentForm, setOpenContentForm] = useState(false);
    const [contentIndex, setContentIndex] = useState(formData.data._id);
    const [contentDetail, setContentDetail] = useState({});

    const openContentFormModel = () => {
        setOpenContentForm(!openContentForm);
    };

    const getContent = async (id) => {
        const data = await getBookContent(id);
        setContentDetail(data.data);
    }

    const updateContentDetail = async () => {
        const data = contentDetail;
        data.indexId = contentIndex;
        data.bookId = formData.bookDetail._id;
        await updateBookContent(contentDetail._id, data);
    }

    const createContentDetail = async () => {
        const data = contentDetail;
        data.indexId = contentIndex;
        data.bookId = formData.bookDetail._id;
        const updateData = await createBookContent(data);
        setContentDetail(updateData.data);
    }

    useEffect(() => {
        getContent(contentIndex);
    }, [contentIndex])

    return (
        <div>
            <div className="h-[calc(100vh_-_55px)] p-5 pb-5 bg-[#F8F8F8] overflow-auto">
                <div className="flex items-center justify-between pb-[15px] mb-[20px] border-b-[#D7D7D7] border-b border-solid">
                    <div className="flex items-center">
                        <Icon
                            icon="icon-park-solid:left-c"
                            className="text-3xl mr-3 cursor-pointer"
                            onClick={() => navigate(`/book/list/${formData?.bookDetail?._id}/${formData?.parentIndex?._id}`, { data: formData.parentIndex, bookDetail: formData.bookDetail })}
                        />
                        <div className="text-[25px] text-[#212121]">
                            {formData.data.title}
                        </div>
                    </div>
                </div>
                <BookContent
                    contentDetail={contentDetail}
                    setContentDetail={setContentDetail}
                    updateContentDetail={updateContentDetail}
                    createContentDetail={createContentDetail}
                />
            </div>
        </div>
    )
}

export default BookListText;