import React, { useEffect, useState } from "react";
import lodash from "lodash";
import { useDispatch, useSelector } from "react-redux";
import MemberFilters from "./MemberFilters";
import { toastr } from "../../../services/common.service";

import {
  downloadProfilePhotos,
  getCommonFieldList,
  getMembersList,
  unblockUserAccount,
  updateUserType,
} from "./actionCreator";
import { ACTION_TYPES } from "../../../redux/constants";
import MemberProfile from "./Member-profile/memberProfile";
import { updateUser } from "../add-member/actionCreator";
import MemberTable2 from "./MemberTable2";

const Members = () => {
  const dispatch = useDispatch();
  const { MemberList: MemberMasterList } = useSelector(
    (state) => state.MembersReducer
  );

  const [filteredMemberList, setFilteredMemberList] = useState([]);
  const [_memberFilters, _setMemberFilters] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [collapseProfile, setCollapseProfile] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const [memberDetails, setMemberDetails] = useState();
  const [tableData, setTableData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [sortConfig, setSortConfig] = useState([]);
  const [disablePaginationButton, setDisablePaginationButton] = useState(false);

  const toggleSort = (key) => {
    setSortConfig((prevConfig) => {
      const existingConfig = prevConfig.find((config) => config.key === key);
      if (existingConfig) {
        return prevConfig.map((config) =>
          config.key === key
            ? {
                ...config,
                direction: config.direction === "asc" ? "desc" : "asc",
              }
            : config
        );
      }
      return [{ key, direction: "asc" }];
    });
  };

  const handleCollapseProfile = (memberData) => {
    if (memberDetails?._id === memberData?._id) {
      setCollapseProfile(!collapseProfile);
    } else {
      setMemberDetails(memberData);
      setCollapseProfile(true);
    }
  };

  const unblockedUserAccount = async () => {
    try {
      const response = await unblockUserAccount(memberDetails._id);
      setEnabled(!enabled);
      if (response?.data) {
        memberDetails.loginRetryLimit = 0;
        toastr.show("User account unblocked successfully.", "success");
        fetchAllMembers();
      }
    } catch (e) {
      toastr.show("Failed to unblock user account.", "error");
    }
  };

  const changeUserType = async () => {
    try {
      const response = await updateUserType(memberDetails._id);
      setEnabled(!enabled);
      if (response?.data) {
        memberDetails.isMantraLekhanUser = response?.data?.isMantraLekhanUser;
        toastr.show("User Type Changed", "success");
        fetchAllMembers();
      }
    } catch (e) {
      toastr.show("Failed to change user type.", "error");
    }
  };

  const handleCloseProfile = () => {
    setCollapseProfile(false);
  };

  useEffect(() => {
    fetchAllMembers(_memberFilters);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, sortConfig]);

  useEffect(() => {
    const debouncedFetchResults = lodash.debounce(
      (query) => fetchAllMembers(query),
      2000
    );
    if (_memberFilters.searchTerm) {
      debouncedFetchResults(_memberFilters);
    }
    return () => {
      debouncedFetchResults.cancel();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_memberFilters]);

  useEffect(() => {
    if (MemberMasterList && MemberMasterList.length > 0) {
      filterMemberList();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [MemberMasterList]);

  const fetchCommonFieldList = async () => {
    const response = await getCommonFieldList();
    if (response?.data) {
      dispatch({
        type: ACTION_TYPES.SET_COMMON_FIELDS,
        payload: response.data,
      });
    }
  };

  useEffect(() => {
    fetchCommonFieldList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateQueryParams = (filter) => {
    const queryParams = {};
    for (const key in filter) {
      if (key === "gender") {
        queryParams[key] = filter[key].value;
      } else if (key === "isActive") {
        if (filter[key] === "Active") {
          queryParams[key] = true;
        } else if (filter[key] === "InActive") {
          queryParams[key] = false;
        }
      } else if (Array.isArray(filter[key])) {
        let arrVal = filter[key].map((item) => item.value);
        if (arrVal.length > 0) {
          queryParams[key] = arrVal.join(",");
        }
      } else if (key === "birthMonth") {
        queryParams[key] = filter[key]?.value;
      } else {
        queryParams[key] = filter[key];
      }
    }
    return queryParams;
  };

  const fetchAllMembers = async (filter = _memberFilters) => {
    try {
      setDisablePaginationButton(true);
      const queryParams = generateQueryParams(filter);
      const sortQuery = sortConfig
        .map(
          (config) => `${config.direction === "desc" ? "-" : ""}${config.key}`
        )
        .join(",");

      let response;

      if (Object.keys(filter).length > 0) {
        response = await getMembersList({
          currentPage,
          pageSize,
          queryParams,
          sortQuery,
        });
      } else {
        response = await getMembersList({ currentPage, pageSize, sortQuery });
      }
      if (response?.data) {
        dispatch({
          type: ACTION_TYPES.SET_MEMBERS_LIST,
          payload: response.data.users,
        });
        setFilteredMemberList(response.data.users);
        setTotalPages(response.data.totalPages);
        setCurrentPage(response.data.currentPage);
      }
      setIsLoading(false);
      setDisablePaginationButton(false);
    } catch (err) {
      dispatch({ type: ACTION_TYPES.SET_MEMBERS_LIST, payload: [] });
      setIsLoading(false);
      toastr.show("Fetching Members Failed.", "error");
    }
  };

  const membersWithoutPagination = async (filter = _memberFilters) => {
    try {
      const queryParams = generateQueryParams(filter);
      const sortQuery = sortConfig
        .map(
          (config) => `${config.direction === "desc" ? "-" : ""}${config.key}`
        )
        .join(",");
      let response;
      if (Object.keys(filter).length > 0) {
        response = await getMembersList({
          currentPage,
          queryParams,
          sortQuery,
        });
      } else {
        response = await getMembersList({ currentPage, sortQuery });
      }
      if (response?.data) {
        return response.data.users;
      }
      return [];
    } catch (err) {
      return [];
    }
  };
  const filterMemberList = (__memberFilters) => {
    let __filteredMemberList = lodash.cloneDeep(MemberMasterList);
    setFilteredMemberList(__filteredMemberList || []);
  };

  const setMemberFilters = async (key, value, action) => {
    const __memberFilters = lodash.cloneDeep(_memberFilters);
    if (value !== undefined && value !== null && value !== "") {
      __memberFilters[key] = value;
    } else {
      delete __memberFilters[key];
    }
    if (action === "remove" || key === "searchTerm" || key === "SMVNo") {
      fetchAllMembers(__memberFilters);
    }
    filterMemberList(__memberFilters);
    _setMemberFilters(__memberFilters);
  };

  const resetFilters = async () => {
    try {
      filterMemberList({});
      _setMemberFilters({});
      const response = await getMembersList({ currentPage, pageSize });
      setTotalPages(response.data.totalPages);
      setFilteredMemberList(response.data.users);
    } catch (error) {
      setIsLoading(false);
      toastr.show("Fetching Members Failed.", "error");
    }
  };

  const _SetMemberDetails = (field, value) => {
    const _memberDetails = lodash.cloneDeep(memberDetails);
    if (["mobile", "mobile2", "referrerMobile"].includes(field)) {
      if (value.length > 0) {
        let mobileValue = Number(value);
        if (!mobileValue) return;
        else value = mobileValue;
      }
      _memberDetails[field] = value;
      setMemberDetails(_memberDetails);
    } else {
      _memberDetails[field] = value;
      if (
        [
          "blockNo",
          "streetName",
          "society",
          "landmark",
          "city",
          "state",
          "postalCode",
          "country",
        ].includes(field)
      ) {
        if (!_memberDetails["address"]) _memberDetails["address"] = {};
        _memberDetails["address"][field] = value;
        setMemberDetails(_memberDetails);
      } else {
        if (field.includes("foreign")) {
          const fieldKey = field.split("_")[1];
          const foreignDetail = _memberDetails?.foreign || {};
          if (
            [
              "blockNo",
              "streetName",
              "society",
              "landmark",
              "city",
              "state",
              "postalCode",
              "country",
            ].includes(fieldKey)
          ) {
            if (!foreignDetail["address"]) foreignDetail["address"] = {};
            foreignDetail["address"][fieldKey] = value;
          } else {
            foreignDetail[fieldKey] = value;
          }
          _memberDetails["foreign"] = foreignDetail;
          delete _memberDetails[field];
        }
        setMemberDetails(_memberDetails);
      }
    }
  };

  const getDownloadProfilePhotos = async () => {
    try {
      const queryParams = generateQueryParams(_memberFilters);
      const response = await downloadProfilePhotos(queryParams);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "images.zip"); // or any other extension
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      toastr.show("Failed to download profile photos.", "error");
    }
  };

  const updateMember = async () => {
    try {
      memberDetails.address = {
        blockNo: memberDetails.blockNo || memberDetails?.address?.blockNo,
        streetName:
          memberDetails.streetName || memberDetails?.address?.streetName,
        society: memberDetails.society || memberDetails?.address?.society,
        landmark: memberDetails.landmark || memberDetails?.address?.landmark,
        city: memberDetails.city || memberDetails?.address?.city,
        state: memberDetails.state || memberDetails?.address?.state,
        postalCode:
          memberDetails.postalCode || memberDetails?.address?.postalCode,
        country: memberDetails.country || memberDetails?.address?.country,
      };

      const userData = lodash.cloneDeep(memberDetails);
      delete userData.attendeeOf;

      if (userData?.entryYear) userData.entryYear = Number(userData?.entryYear);
      userData.mobile = userData?.mobile?.toString();
      userData.countryCode = userData?.countryCode || "91";
      userData.countryCode2 = userData?.countryCode2 || "91";
      if (userData.mobile2) userData.mobile2 = userData?.mobile2?.toString();
      if (userData.referrerMobile)
        userData.referrerMobile = userData?.referrerMobile?.toString();
      userData.referrerCountryCode = userData?.referrerCountryCode || "91";
      userData._id = memberDetails._id;
      if (userData?.address?.postalCode)
        userData.address.postalCode = userData?.address?.postalCode.toString();
      let updateData = await updateUser(userData);
      dispatch({
        type: ACTION_TYPES.UPDATE_MEMBER,
        payload: updateData.data,
      });
    } catch (e) {}
  };

  return (
    <>
      <MemberFilters
        _memberFilters={_memberFilters}
        setMemberFilters={setMemberFilters}
        resetFilters={resetFilters}
        tableData={tableData}
        setFilteredMemberList={setFilteredMemberList}
        setTotalPages={setTotalPages}
        fetchMember={fetchAllMembers}
      />

      <div className="flex w-full">
        <div className="px-3 flex-grow border border-stone-200 rounded-lg overflow-auto w-full">
          <MemberTable2
            isLoading={isLoading}
            downloadProfilePhotos={getDownloadProfilePhotos}
            filteredMemberList={filteredMemberList}
            handleCollapsePofile={handleCollapseProfile}
            memberDetails={memberDetails}
            setTableData={setTableData}
            totalPages={totalPages}
            setPageSize={setPageSize}
            pageSize={pageSize}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            _memberFilters={_memberFilters}
            setMemberFilters={setMemberFilters}
            toggleSort={toggleSort}
            sortConfig={sortConfig}
            disablePaginationButton={disablePaginationButton}
            membersWithoutPagination={membersWithoutPagination}
          />
        </div>
        <div
          className={`max-w-[450px] 2xl:max-w-[600px] w-full relative overflow-auto h-[calc(100vh_-_110px)] transition-all ease-out duration-100' ${
            collapseProfile ? "mr-0" : "-mr-[450px] 2xl:-mr-[600px]"
          }`}
        >
          {memberDetails && (
            <MemberProfile
              profileData={memberDetails}
              setMemberDetails={_SetMemberDetails}
              updateMember={updateMember}
              unblockedUserAccount={unblockedUserAccount}
              changeUserType={changeUserType}
              handleCloseProfile={handleCloseProfile}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Members;
