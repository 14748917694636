import { Button, Checkbox } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Icon } from "@iconify/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { getProfileImage, toastr } from "../../services/common.service";
import MemberFormModal from "./MemberFormModal";
import FilterMenu from "./FilterMenu";
import lodash from "lodash";
import SelectField from "../Common/Input/SelectField";
import {
  genderType,
  GROUP_NUMBERS,
  SABHA_TYPES,
} from "../../constants/common.constants";
import { generateDateRange } from "../../utils/generateDateRange";
import { useSelector } from "react-redux";
import CreatableMultiselectMain from "../Common/Input/CreatableMultiselectMain";
import {
  deleteUserSubmittedForm,
  getUsersSubmittedForm,
} from "./actionCreator";
import DeleteModal from "../Common/DeleteModal/DeleteModal";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import { s3Url } from "../../services/api.service";
import ColumnVisibilityMenu from "../attendance/memberlist/ColumnVisibilityMenu";
import PdfComponent from "../PDF";

const columnHelper = createColumnHelper();

const ViewApplicantUsers = () => {
  const [data, setData] = useState([]);
  const [userId, setUserId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [openMemberForm, setOpenMemberForm] = useState(false);
  const [filters, setFilters] = useState({});
  const [dates, setDates] = useState([]);
  const [checkedDates, setCheckedDates] = useState({});
  const [selectedForm, setSelectedForm] = useState({});
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const totalFields = {
    SMVNo: true,
    profileImgUrl: true,
    fullName: true,
    age: true,
    mobile: true,
    daysToJoinEvent: true,
    gn_kishorsabha: false,
    gn_yuvasabha: false,
    gn_atmiyasabha: false,
    gn_foreignsabha: false,
  };

  const [columnVisibility, setColumnVisibility] = useState(totalFields);
  const [headers, setHeaders] = useState([]);

  const navigate = useNavigate();
  const { HODList: Departments } = useSelector(
    (state) => state.SaintLeaderReducer
  );

  const openMemberFormLink = () => {
    setOpenMemberForm(!openMemberForm);
  };

  const location = useLocation();
  const formData = location.state?.data;

  useEffect(() => {
    getFormData();
  }, []);

  useEffect(() => {
    const visibleFields = Object.keys(columnVisibility).filter(
      (field) => columnVisibility[field]
    );
    setHeaders(visibleFields);
  }, [columnVisibility]);

  const getFormData = async () => {
    const newData = lodash.cloneDeep(filters);
    if (filters.sabhas?.length) {
      newData.sabhas = filters.sabhas.map((s) => s.value);
    }
    if (filters.department?.length) {
      newData.department = filters.department.map((s) => s.value);
    }
    if (filters.groupNos?.length) {
      newData.groupNos = filters.groupNos.map((s) => s.value);
    }
    if (filters.gender) {
      newData.gender = filters.gender.value;
    }
    const selectedDates = Object.entries(checkedDates)
      .filter(([key, value]) => value === true)
      .map(([key]) => key);
    if (selectedDates?.length) {
      newData.dates = selectedDates;
    }
    const usersData = await getUsersSubmittedForm(formData?._id, newData);
    setData(usersData.data || []);
  };

  useEffect(() => {
    const debouncedFetchResults = lodash.debounce(() => {
      getFormData(); // Pass the function reference here
    }, 1000);
    if (filters.searchTerm !== '' || filters.SMVNo !== '') {
      debouncedFetchResults();
    }
    return () => {
      debouncedFetchResults.cancel();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters?.searchTerm, filters?.SMVNo]);


  const openCreateLinkModal = (_id = null) => {
    if (_id) {
      const form = data.find((f) => f._id + "" === _id + "");
      setSelectedForm(form);
    } else {
      setSelectedForm({});
    }
    setOpenMemberForm(!openMemberForm);
  };

  const handleCloseModal = async (status) => {
    if (status) {
      try {
        const res = await deleteUserSubmittedForm(deleteId, {
          formId: formData?._id,
          userId: userId,
        });
        if (res.data) {
          toastr.show("Deleted Successfully", "success");
          const newList = data?.filter((f) => f._id + "" !== deleteId + "");
          setData(newList);
        }
      } catch (error) {
        toastr.show("Something Went Wroing", "error");
      }
    }
    setShowDeleteModal(false);
    setDeleteId("");
    setUserId("");
  };

  const handleOpenDeleteModal = (id, userId) => {
    setDeleteId(id);
    setUserId(userId._id);
    setShowDeleteModal(!showDeleteModal);
  };

  const setMemberFilters = async (key, value) => {
    const _filters = lodash.cloneDeep(filters);
    if (value !== undefined && value !== null && value !== "") {
      _filters[key] = value;
    } else {
      delete _filters[key];
    }
    setFilters(_filters);
  };

  const columns = [
    {
      accessorKey: "SMVNo",
      header: () => <span>SMV NO.</span>,
      cell: (info) => info.getValue(),
      id: "SMVNo",
      size: 150,
      maxSize: 150,
    },
    {
      header: "Personal Info.",
      columns: [
        {
          accessorKey: "profileImgUrl",
          header: () => <span>Photo</span>,
          cell: (info) => {
            const profilePhoto = getProfileImage(
              { profileImgUrl: info?.row?.original?.userId?.profileImgUrl },
              info?.row?.original?.gender
            );
            return (
              <img
                className=" min-w-[40px] max-w-[40px] mx-auto max-h-[40px] rounded-full object-cover"
                alt="profile"
                src={profilePhoto}
              />
            );
          },
          id: "profileImgUrl",
          size: 150,
        },
        {
          accessorKey: "fullName",
          header: () => <span>Name.</span>,
          cell: (info) => (
            <div className="">
              {info.row.original?.userId.firstName}{" "}
              {info.row.original?.userId.middleName}{" "}
              {info.row.original?.userId.lastName}
            </div>
          ),
          id: "fullName",
          // size: 450,
        },

        {
          accessorKey: "userId.age",
          header: () => <span>Age</span>,
          cell: (info) => info.getValue(),
          id: "age",
          size: 150,
        },
        {
          accessorKey: "userId.mobile",
          header: () => <span>Mobile</span>,
          cell: (info) => info.getValue(),
          id: "mobile",
          size: 150,
        },
      ],
    },
    {
      header: "Group Number",
      columns: [
        {
          accessorKey: "gn_kishorsabha",
          header: () => <span>Kishor sabha</span>,
          cell: (info) => {
            const kishorSabhaDetails =
              info.row.original?.userId?.attendeeOf?.find(
                (sabha) => sabha.sabhaName === "Kishor Sabha"
              );
            return <span>{kishorSabhaDetails?.groupNo}</span>;
          },
          sortingFn: (rowA, rowB) => {
            const groupA = rowA.original.userId.attendeeOf.find(sabha => sabha.sabhaName === "Kishor Sabha")?.groupNo;
            const groupB = rowB.original.userId.attendeeOf.find(sabha => sabha.sabhaName === "Kishor Sabha")?.groupNo;
            return (groupA ?? "").localeCompare(groupB ?? "");
          },
          id: "gn_kishorsabha",
          size: 150,
          maxSize: 150,
        },
        {
          accessorKey: "gn_yuvasabha",
          header: () => <span>Yuva sabha</span>,
          cell: (info) => {
            const yuvaSabhaDetails =
              info.row.original?.userId?.attendeeOf?.find(
                (sabha) => sabha.sabhaName === "Yuva Sabha"
              );
            return <span>{yuvaSabhaDetails?.groupNo}</span>;
          },
          id: "gn_yuvasabha",
          size: 150,
          maxSize: 150,
          sortingFn: (rowA, rowB) => {
            const groupA = rowA.original.userId.attendeeOf.find(sabha => sabha.sabhaName === "Yuva Sabha")?.groupNo;
            const groupB = rowB.original.userId.attendeeOf.find(sabha => sabha.sabhaName === "Yuva Sabha")?.groupNo;
            return (groupA ?? "").localeCompare(groupB ?? "");
          },
        },
        {
          accessorKey: "gn_atmiyasabha",
          header: () => <span>Atmiya sabha</span>,
          cell: (info) => {
            const atmiyaSabhaDetails =
              info.row.original?.userId?.attendeeOf?.find(
                (sabha) => sabha.sabhaName === "Atmiya Sabha"
              );
            return <span>{atmiyaSabhaDetails?.groupNo}</span>;
          },
          sortingFn: (rowA, rowB) => {
            const groupA = rowA.original.userId.attendeeOf.find(sabha => sabha.sabhaName === "Atmiya Sabha")?.groupNo;
            const groupB = rowB.original.userId.attendeeOf.find(sabha => sabha.sabhaName === "Atmiya Sabha")?.groupNo;
            return (groupA ?? "").localeCompare(groupB ?? "");
          },
          id: "gn_atmiyasabha",
          size: 150,
          maxSize: 150,
        },
        {
          accessorKey: "gn_foreignsabha",
          header: () => <span>Foreign sabha</span>,
          cell: (info) => {
            const foreignSabhaDetails =
              info.row.original?.userId?.attendeeOf?.find(
                (sabha) => sabha.sabhaName === "Foreigner Sabha"
              );
            return <span>{foreignSabhaDetails?.groupNo}</span>;
          },
          id: "gn_foreignsabha",
          size: 150,
          maxSize: 150,
        },
      ],
    },
    {
      accessorKey: "daysToJoinEvent",
      header: () => <span>Total</span>,
      cell: (info) => info.getValue(),
      id: "daysToJoinEvent",
      size: 150,
    },
    {
      header: "Attendance",
      id: "attendance",
      columns: [
        {
          accessorKey: "kishorAttd",
          header: () => <span>Sabha</span>,
          cell: (info) => {
            const recentAttendance = info.row.original?.recentAttendance
            return (
              <>
                {recentAttendance?.map((_attn, index) => {
                  return (
                    <>
                      <Icon
                        key={"kishor" + info.row.original?._id + index}
                        size={"10px"}
                        className={`inline mx-0.5 ${
                          _attn?.present ? "text-[#16C046]" : "text-red-600"
                        }`}
                        icon="material-symbols:circle"
                      />
                    </>
                  );
                })}
              </>
            );
          },
          id: "kishorAttd",
          size: 150,
        },
      ],
    },
    columnHelper.display({
      header: () => <span className="text-center">Actions</span>,
      id: "actions",
      cell: (props) => {
        return (
          <span className="flex items-center justify-center">
            <span className="w-9 h-9 border border-black900 rounded-xl transition-all duration-150 flex justify-center items-center mr-2 cursor-pointer group text-black900">
              <button>
                <Icon
                  icon="ic:baseline-edit"
                  className="text-2xl transition-all duration-150 text-black900"
                  onClick={() => openCreateLinkModal(props.row.original?._id)}
                />
              </button>
            </span>
            <span className="w-9 h-9 border border-black900 rounded-xl transition-all duration-150 flex justify-center items-center mr-2 cursor-pointer group hover:text-black900">
              <button>
                <Icon
                  icon="material-symbols:delete"
                  className="transition-all duration-150 text-2xl text-black900"
                  onClick={() =>
                    handleOpenDeleteModal(
                      props.row.original?._id,
                      props.row.original?.userId
                    )
                  }
                />
              </button>
            </span>
          </span>
        );
      },
    }),
  ];

  const table = useReactTable(
    {
      data,
      columns,
      getCoreRowModel: getCoreRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      onPaginationChange: setPagination,
      onColumnVisibilityChange: setColumnVisibility,
      getSortedRowModel: getSortedRowModel(),
      getRowId: (row) => row.id,
      initialState: {
        columnVisibility: columnVisibility,
      },
      onSortingChange: setSorting,
      state: {
        columnVisibility,
        pagination,
        sorting,
      },
    },
    [data]
  );

  const ageHandler = (input, type) => {
    setMemberFilters(type, input);
  };

  useEffect(() => {
    const start = new Date(formData.fromDate);
    const end = new Date(formData.toDate);
    const rangeDates = generateDateRange(start, end);
    setDates(rangeDates);
  }, []);

  const handleCheckboxChange = (date) => {
    setCheckedDates((prev) => ({
      ...prev,
      [date]: !prev[date],
    }));
  };

  const convertDataInFormat = (filteredMembers, visibleFields) => {
    const filterFields = filteredMembers.map((member) => {
      let filteredMember = {};
      visibleFields.forEach((field) => {
        if (field === "fullName") {
          filteredMember[field] =
            (member.userId.firstName ? member.userId.firstName : "") +
            (member.userId.middleName ? " " + member.userId.middleName : "") +
            (member.userId.lastName ? " " + member.userId.lastName : "");
        } else if (field === "profileImgUrl") {
          filteredMember["Profile Image"] = member?.userId?.[field]
            ? `${s3Url}${member.userId[field]}`
            : "";
        } else if (field === "age") {
          filteredMember[field] = member.userId?.age;
        } else if (field === "mobile") {
          filteredMember[field] = member.userId.mobile;
        } else if (['gn_kishorsabha', 'gn_yuvasabha', 'gn_atmiyasabha', 'gn_foreignsabha'].includes(field)) {
          if (member.userId.attendeeOf) {
            filteredMember[field] = member.userId.attendeeOf.find((sabha) =>
              (sabha.sabhaName === "Kishor Sabha" && field === "gn_kishorsabha") ||
              (sabha.sabhaName === "Yuva Sabha" && field === "gn_yuvasabha") ||
              (sabha.sabhaName === "Atmiya Sabha" && field === "gn_atmiyasabha") ||
              (sabha.sabhaName === "Foreigner Sabha" && field === "gn_foreignsabha")
            )?.groupNo || "";
          }
        } else {
          filteredMember[field] = member[field];
        }
      });
      return filteredMember;
    });
    return filterFields;
  };

  const getDownloadCsvFile = async (downloadType = "XLSX") => {
    try {
      if (data?.length) {
        const filteredMembers = data;
        const visibleFields = headers.filter((id) => id !== "profileImgUrl" && id !== "actions");
        const filterFields = convertDataInFormat(
          filteredMembers,
          visibleFields
        );
        const worksheet = XLSX.utils.json_to_sheet(filterFields);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Members");
        XLSX.writeFile(workbook, "members_list.xlsx");
        toastr.show("Successfully Download", "success");
      } else {
        toastr.show("No data found", "warning");
      }
    } catch (err) {
      toastr.show("Failed to download Excel Data.", "error");
    }
  };

  return (
    <>
      <div className="h-[calc(100vh_-_55px)] p-5 pb-5 bg-[#F8F8F8] overflow-auto">
        <div className="flex items-center justify-between pb-[15px] mb-[20px] border-b-[#D7D7D7] border-b border-solid">
          <div className="flex items-center">
            <Icon
              icon="icon-park-solid:left-c"
              className="text-3xl mr-3 cursor-pointer"
              onClick={() => navigate("/form/list/")}
            />
            <div className="text-[25px] text-[#212121]">
              Form Applicants List
            </div>
          </div>
          <Button
            color="blue"
            className="flex items-center min-w-[150px]"
            onClick={openMemberFormLink}
          >
            <Icon icon="ic:baseline-plus" className="mr-1 text-2xl" /> Submit
            Form
          </Button>
        </div>
        <div className="flex justify-between items-center mb-3 flex-wrap xl:flex-nowrap gap-3">
          <div className="flex items-center flex-1">
            <div className="w-56 relative mr-2">
              <input
                id="search-members"
                type="text"
                placeholder="Search Member"
                autoComplete="off"
                className="bg-white text-sm h-9 w-full text-black900 outline-none px-2.5 py-2 rounded-lg placeholder:text-stone-400 placeholder:text-sm caret-stone-400 placeholder:pl-0.5"
                value={filters?.searchTerm}
                onChange={(e) => {
                  setMemberFilters("searchTerm", e.target.value);
                }}
              />
              <Icon
                icon="iconamoon:search-light"
                className="absolute right-2 top-[50%] translate-y-[-50%] text-lg text-[#969696]"
              />
            </div>
            <div className="w-56 relative">
              <input
                id="search-members"
                type="text"
                placeholder="Search SMVNo"
                autoComplete="off"
                className="bg-white text-sm h-9 w-full text-black900 outline-none px-2.5 py-2 rounded-lg placeholder:text-stone-400 placeholder:text-sm caret-stone-400 placeholder:pl-0.5"
                value={filters?.SMVNo}
                onChange={(e) => {
                  setMemberFilters("SMVNo", e.target.value);
                }}
              />
              <Icon
                icon="iconamoon:search-light"
                className="absolute right-2 top-[50%] translate-y-[-50%] text-lg text-[#969696]"
              />
            </div>
            <div className="ml-2 text-[#212121] flex items-center">
              <span className="mr-2">Total Count</span>
              <span className="bg-black rounded-md text-white w-8 h-8 grid place-items-center">{data?.length}</span>
            </div>
          </div>
          <div className="flex gap-3 flex-1 justify-end">
            <ColumnVisibilityMenu table={table} />
            <button
              className="inline-flex items-center bg-[#1D6F42] border border-[#1D6F42] text-white p-2 rounded-md"
              onClick={() => getDownloadCsvFile("XLSX")}
            >
              <Icon
                icon="ri:file-excel-2-line"
                className="inline mr-1 text-xl"
              />
              Excel
            </button>
            <PdfComponent
              headers={headers}
              _memberFilters={filters}
              convertDataInFormat={convertDataInFormat}
              userData={data}
              serviceName={"fromReport"}
            ></PdfComponent>
            <FilterMenu
              menuStyle={"w-[600px] shadow-md"}
              buttonElem={
                <button className="inline-flex items-center bg-primary border border-primary text-white p-2 rounded">
                  <Icon icon="mdi:filter" className="inline mr-1 text-lg" />
                  Filter
                </button>
              }
            >
              {({ close }) => (
                <div className="">
                  <div className="grid grid-cols-2 gap-3">
                    <div className="p-3 shadow-md bg-[#EFF5FB]  rounded-md">
                      <div className="text-sm text-black900 font-semibold border-none py-2 mb-1">
                        Gender
                      </div>
                      <div className="pt-0 w-full">
                        <SelectField
                          className="!h-9 placeholder:text-black900"
                          required={false}
                          placeholder="Select Gender"
                          isMulti={false}
                          defaultOptions={genderType}
                          value={filters.gender || []}
                          onChange={(val) => {
                            setMemberFilters("gender", val);
                          }}
                        />
                      </div>
                    </div>
                    <div className="p-3 shadow-md bg-[#EFF5FB] rounded-md">
                      <div className="text-sm text-black900 font-semibold border-none py-2 mb-1">
                        Age
                      </div>
                      <div>
                        <div className="flex gap-2 w-full">
                          <input
                            type="number"
                            min={0}
                            max={100}
                            value={filters?.minAge}
                            placeholder="MIN"
                            onChange={(e) => {
                              ageHandler(e.target.value, "minAge");
                            }}
                            className="max-w-[80px] h-9 p-1.5 outline-none border rounded-md border-gray-400 appearance-none placeholder:text-gray-700"
                          />
                          <input
                            type="number"
                            min={0}
                            max={100}
                            value={filters?.maxAge}
                            placeholder="MAX"
                            onChange={(e) => {
                              ageHandler(e.target.value, "maxAge");
                            }}
                            className="max-w-[80px] h-9 p-1.5 outline-none border rounded-md border-gray-400 appearance-none placeholder:text-gray-700"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="p-3 shadow-md bg-[#EFF5FB] rounded-md col-span-2">
                      <div className="text-sm text-black900 font-semibold border-none py-2 mb-1">
                        Member Attendence
                      </div>
                      <div className="p-3 rounded-md bg-white">
                        <div className="grid grid-cols-4">
                          {dates.map((date) => {
                            const dateString = moment(date).format("DD/MM/YYYY");
                            return (
                              <div key={dateString}>
                                <div className="flex items-center">
                                  <Checkbox
                                    checked={!!checkedDates[dateString]}
                                    label={dateString}
                                    onChange={() =>
                                      handleCheckboxChange(dateString)
                                    }
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="p-3 shadow-md bg-[#EFF5FB] rounded-md col-span-2">
                      <div className="text-sm text-black900 font-semibold border-none py-2 mb-1">
                        Sabha
                      </div>
                      <div className="flex flex-wrap justify-between gap-2">
                        {SABHA_TYPES.map((sabha) => {
                          return (
                            <div
                              className={`px-1.5 py-1.5 text-[12px] flex-1 ${filters.sabhas?.find(
                                (d) => d.label === sabha.name
                              )
                                ? "bg-blue-600 text-white"
                                : "text-gray300 border-gray300 bg-white"
                                } rounded-md select-none border cursor-pointer text-center`}
                              onClick={() => {
                                let val = [...(filters.sabhas || [])];
                                if (val.find((d) => d.label === sabha.name)) {
                                  val = val.filter((d) => d.label !== sabha.name);
                                } else {
                                  val.push({
                                    label: sabha.name,
                                    value: sabha._id,
                                  });
                                }
                                setMemberFilters("sabhas", val);
                              }}
                            >
                              {sabha.name}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    {filters.sabhas?.length > 0 && (
                      <div className="p-3 shadow-md bg-[#EFF5FB] rounded-md  col-span-2">
                        <div className="text-sm text-black900 font-semibold border-none py-2 mb-1">
                          Department
                        </div>
                        <div className="grid grid-cols-4 gap-2">
                          {Departments.filter((dept) =>
                            filters.sabhas
                              .map((d) => d.value)
                              .includes(dept.sabhaId)
                          ).map((dept) => {
                            return (
                              <div
                                className={`px-1.5 py-1.5 text-[12px] ${filters.department?.find(
                                  (d) => d.value === dept._id
                                )
                                  ? "bg-blue-600 text-white"
                                  : "text-gray300 border-gray300 bg-white"
                                  } rounded-md select-none border cursor-pointer text-center`}
                                onClick={() => {
                                  let val = [...(filters.department || [])];
                                  if (val.find((d) => d.value === dept._id)) {
                                    val = val.filter((d) => d.value !== dept._id);
                                  } else {
                                    val.push({
                                      label: dept.departmentName,
                                      value: dept._id,
                                    });
                                  }
                                  setMemberFilters("department", val);
                                }}
                              >
                                {dept.departmentName}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                    <div className="p-3 shadow-md bg-[#EFF5FB] rounded-md col-span-2">
                      <div className="text-sm text-black900 font-semibold border-none py-2 mb-1">
                        Group No.
                      </div>
                      <div className="pt-0 w-full">
                        <CreatableMultiselectMain
                          id="16"
                          className="!h-9 placeholder:text-black900"
                          required={false}
                          placeholder="Select Group No"
                          isMulti={true}
                          options={GROUP_NUMBERS}
                          value={filters.groupNos || []}
                          onChange={(val) => {
                            setMemberFilters("groupNos", val);
                          }}
                        />
                      </div>
                    </div>
                    <div className="p-3 shadow-md bg-[#EFF5FB] rounded-md col-span-2">
                      <div className="text-sm text-black900 font-semibold border-none py-2">
                        Role
                      </div>
                      <div className="flex items-center mb-2">
                        <div className="w-1/3">
                          <Checkbox
                            label="All Leaders"
                            checked={filters?.allLeaders || false}
                            onChange={(value) =>
                              setMemberFilters(
                                "allLeaders",
                                value?.target?.checked
                              )
                            }
                          />
                        </div>
                        <div className="w-1/3">
                          <Checkbox
                            label="All HOD"
                            checked={filters?.allHOD || false}
                            onChange={(value) =>
                              setMemberFilters("allHOD", value?.target?.checked)
                            }
                          />
                        </div>
                        <div className="w-1/3">
                          <Checkbox
                            label="All HOL"
                            checked={filters?.allHOL || false}
                            onChange={(value) =>
                              setMemberFilters("allHOL", value?.target?.checked)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end py-3">
                    <button
                      class="text-white/90 group inline-flex items-center rounded-md mr-3 bg-red-400 px-4 py-2 text-sm font-medium hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75"
                      onClick={() => {
                        setFilters({})
                      }}
                    >
                      Reset
                    </button>
                    <button
                      class="text-white/90 group inline-flex items-center rounded-md bg-primary px-4 py-2 text-sm font-medium hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75"
                      onClick={() => {
                        getFormData()
                      }}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              )}
            </FilterMenu>
          </div>
        </div>

        <div className="bg-white w-full px-6 py-[30px] overflow-hidden rounded-[10px] shadow-[1px_-1px_10px_0px_rgba(0,0,0,0.10)] h-[calc(100%_-_215px)]">
          <div className="overflow-auto h-full">
            <table className="w-full">
              <thead className="sticky top-0">
                {table.getHeaderGroups().map((headerGroup) => {
                  return (
                    <tr className="border-b border-stone-200 text-sm bg-[#F2F2F2] text-[#535353]">
                      {headerGroup.headers.map((header) => {
                        return (
                          <th
                            key={header.id}
                            colSpan={header.colSpan}
                            className="text-center font-semibold p-2 border border-gray-300"
                          >
                            {header.isPlaceholder ? null : (
                              <div
                                className={
                                  header.column.getCanSort()
                                    ? "cursor-pointer select-none justify-center flex items-center"
                                    : ""
                                }
                                onClick={header.column.getToggleSortingHandler()}
                                title={
                                  header.column.getCanSort()
                                    ? header.column.getNextSortingOrder() ===
                                      "asc"
                                      ? "Sort ascending"
                                      : header.column.getNextSortingOrder() ===
                                        "desc"
                                        ? "Sort descending"
                                        : "Clear sort"
                                    : undefined
                                }
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                                {{
                                  asc: <Icon icon="vaadin:sort" />,
                                  desc: <Icon icon="vaadin:sort" />,
                                }[header.column.getIsSorted()] ?? null}
                              </div>
                            )}
                          </th>
                        );
                      })}
                    </tr>
                  );
                })}
              </thead>
              <tbody className=" border border-gray-200">
                {table.getRowModel().rows.map((row) => {
                  return (
                    <tr key={row.id} className=" text-sm">
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <td
                            key={cell.id}
                            className="px-2 py-1.5 border border-gray-200 text-center"
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex items-center justify-end gap-2 mt-[20px] sticky py-4 px-4 bg-white border-t-2 border rounded-[10px]">
          <button
            className="border-blue-100 border-b-2 border rounded-lg w-7 h-7 grid place-items-center"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <Icon icon="lucide:chevron-left" className="text-xl" />
          </button>
          <button
            className="border-blue-100 border-b-2 border rounded-lg w-7 h-7 grid place-items-center"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <Icon icon="lucide:chevron-right" className="text-xl" />
          </button>
          <span className="flex items-center gap-1">
            <div>Page</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} of{" "}
              {table.getPageCount().toLocaleString()}
            </strong>
          </span>
          <select
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
            className="outline-none p-1 border-blue-100 border-b-2 border rounded-lg py-0.5 "
          >
            {[10, 20, 30, 40, 50].map((page) => (
              <option key={page} value={page}>
                Show {page}
              </option>
            ))}
          </select>
        </div>
      </div>
      <DeleteModal open={showDeleteModal} handleCloseModal={handleCloseModal} />
      {openMemberForm && (
        <MemberFormModal
          openMemberForm={openMemberForm}
          setOpenMemberForm={setOpenMemberForm}
          selectedForm={selectedForm}
          fromDate={formData.fromDate}
          toDate={formData.toDate}
          formIconUrl={formData.formIconUrl}
          formId={formData._id}
          willJoinWithDays={formData.willJoinWithDays}
          userList={data}
          setUserList={setData}
        />
      )}
    </>
  );
};

export default ViewApplicantUsers;
