import React, { useEffect, useRef, useState } from "react";
import { Button } from "@material-tailwind/react";
import { useReactToPrint } from "react-to-print";
import ComponentToPDF from "./ComponentToPDF";
import { downloadCsvFile } from "../attendance/memberlist/actionCreator";
import { Icon } from "@iconify/react/dist/iconify.js";

export default function PdfComponent(props) {
  const { headers, _memberFilters, generateQueryParams, convertDataInFormat, serviceName = "members", userData } = props;
  let componentRef = useRef();
  let printBtnRef = useRef();  
  const [data, setData] = useState([]);

  useEffect(() => {
    if (data.length > 0) {
      setTimeout(() => {
        printBtnRef.current.click();
      }, 100);
    }
  }, [data]);
  
  const myFunction = async () => {
    if (serviceName === "members") {
      const queryParams = generateQueryParams(_memberFilters);
      const response = await downloadCsvFile(queryParams);
      const newData = convertDataInFormat(response?.data, headers);
      setData(newData);
    } else if (serviceName === "fromReport") {
      const newData = convertDataInFormat(userData, headers);
      setData(newData);
    }
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  return (
    <>
      <div>
        <button style={{ display: "none" }} ref={printBtnRef} onClick={handlePrint}>Print</button>
        <button
          className="inline-flex items-center bg-[#F40F02] border border-[#F40F02] text-white p-2 rounded-lg"
          onClick={() => myFunction()}
        >
          <Icon
            icon="ant-design:file-pdf-outlined"
            className="inline mr-1 text-xl"
          />
          PDF
        </button>

        {/* component to be printed */}
        <div style={{ display: "none" }}>
          <ComponentToPDF headers={headers} data={data} innerRef={componentRef} />
        </div>
      </div>
    </>
  );
}