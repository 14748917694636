import React, { useState } from "react";
import { Typography } from "@material-tailwind/react";
import CreateSabhaForm from "./CreateSabhaForm";

const CreateOtherSabha = () => {
  return (
    <div className="p-4 h-[calc(100vh_-_81px)] overflow-auto">
      <div className="mx-auto pb-10">
        <div className="w-max text-center m-2">
          <Typography variant="h6" color={"gray"}>
            Add Sabha
          </Typography>
        </div>
        <CreateSabhaForm />
      </div>
    </div>
  );
};

export default CreateOtherSabha;
