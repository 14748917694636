import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'
import clsx from 'clsx'
import React from 'react'

const FilterMenu = ({ buttonElem, children, menuStyle }) => {
  return (
    <Popover className="relative">
      {({ close }) => (
        <>
          <PopoverButton className="outline-none">{buttonElem}</PopoverButton>
          <PopoverPanel className={clsx(menuStyle, "absolute right-0 bg-white p-5 rounded-md z-10")}>
            {typeof children === 'function' ? children({ close }) : children}

          </PopoverPanel>
        </>
      )}

    </Popover>
  )
}

export default FilterMenu