import React, { useState } from "react";
import Filterbar from "../../Common/Filterbar/Filterbar";
import { FILTER_TYPES } from "../../../constants/filter-constants";
import { Icon } from "@iconify/react";

const MemberFilters = (props) => {
  const {
    _memberFilters,
    setMemberFilters,
    resetFilters,
    setFilteredMemberList,
    setTotalPages,
    fetchMember,
  } = props;

  const [isApplyFilterModalOpen, setIsApplyFilterModalOpen] = useState(false);
  const [applyFilter, setApplyFilter] = useState([]);

  const handleRemoveFilter = async (mFilterKey) => {
    setMemberFilters(mFilterKey, null, "remove");
    let app = [...applyFilter];
    let updatedItem = app.filter((key) => key !== mFilterKey);
    setApplyFilter(updatedItem);
  };

  return (
    <React.Fragment>
      <div>
        <div className="border-b border-stone-200 py-1.5 px-3 flex items-center">
          <div className="flex-grow flex items-center border-r border-stone-200">
            <div className="text-sm px-3 py-1 text-stone-500 font-semibold min-w-[125px]">
              Applied Filters
            </div>
            <div className="text-sm px-3 py-1 flex-grow border-x border-stone-200">
              <ul className="flex flex-wrap gap-y-3">
                {applyFilter.map((mFilterKey) => {
                  return (
                    <li className="text-sm mr-5">
                      {FILTER_TYPES[mFilterKey]}
                      <span className="ml-2 text-primary bg-[#E6EDFB] px-2 py-1 text-[12px] rounded">
                        {Array.isArray(_memberFilters[mFilterKey])
                          ? _memberFilters[mFilterKey]
                              .map((d) => d.label)
                              .join(", ")
                          : _memberFilters[mFilterKey] instanceof Object
                          ? _memberFilters[mFilterKey]?.value
                          : _memberFilters[mFilterKey]}
                        <button
                          //  onClick={() => { setMemberFilters(mFilterKey, null, "remove");fetchMember() }}
                          onClick={() => {
                            handleRemoveFilter(mFilterKey);
                          }}
                        >
                          <Icon
                            icon="material-symbols:close"
                            className="inline cursor-pointer"
                          />
                        </button>
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
            {applyFilter?.length > 0 && (
              <div
                className="px-3 py-1 inline-flex items-center min-w-[95px] text-stone-400 text-[12px] hover:text-red-400 cursor-pointer"
                onClick={() => {
                  resetFilters();
                  setApplyFilter([]);
                }}
              >
                Clear All
                <Icon icon="material-symbols:close" className="inline ml-1" />
              </div>
            )}
          </div>
          <div className={`px-3 py-1`}>
            <button
              className="inline-flex items-center bg-primary text-white text-sm px-2 py-1 rounded"
              onClick={() => setIsApplyFilterModalOpen(true)}
            >
              <Icon icon="mdi:filter" className="inline mr-1" />
              Filter
              {applyFilter?.length > 0 && (
                <span className="bg-white text-primary rounded-full w-3.5 h-3.5 ml-1 inline-flex justify-center items-center text-[10px] font-medium">
                  {applyFilter.length}
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
      {isApplyFilterModalOpen && (
        <Filterbar
          handleClose={() => setIsApplyFilterModalOpen(false)}
          _memberFilters={_memberFilters}
          setMemberFilters={setMemberFilters}
          setFilteredMemberList={setFilteredMemberList}
          setTotalPages={setTotalPages}
          fetchMember={fetchMember}
          setApplyFilter={setApplyFilter}
        />
      )}
    </React.Fragment>
  );
};

export default MemberFilters;
