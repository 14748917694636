import { api } from "../../services/api.service";

function buildQueryParams(params) {
  const queryString = Object.keys(params)
      .map(key => {
          const value = params[key];

          // Check if the value is an array
          if (Array.isArray(value)) {
              return value.map(val => `${encodeURIComponent(key)}[]=${encodeURIComponent(val)}`).join('&');
          }

          if (typeof value === 'object' && value !== null) {
            return `${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(value))}`;
          }

          return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      })
      .join('&');

  return queryString;
}

export const getForm = async () => {
  const response = await api.get("admin/forms");
  return response;
};
  
export const createForm = async (data) => {
  const response = await api.post("admin/forms", data);
  return response;
};

export const updateForm = async (id, data) => {
  const response = await api.put(`admin/forms/${id}`, data);
  return response;
};

export const updateFormFile = async (id, data) => {
  const response = await api.put(`admin/forms/${id}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  });
  return response;
};

export const deleteForm = async (id) => {
  const response = await api.delete(`admin/forms/${id}`);
  return response;
};

export const getUsersSubmittedForm = async (id, queryParams) => {
  const queryString = buildQueryParams(queryParams);
  const response = await api.get(`admin/forms/${id}?${queryString}`);
  return response;
};

export const submitUserForm = async (data) => {
  const response = await api.post("forms/submit", data);
  return response;
};

export const updateUserForm = async (data) => {
  const response = await api.post("forms/update", data);
  return response;
};

export const deleteUserSubmittedForm = async (id, data) => {
  const response = await api.post(`admin/forms/submissions/${id}`, data);
  return response;
};