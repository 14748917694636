import React, { useState, Fragment } from "react"
import { Button, Textarea } from "@material-tailwind/react";
import { Dialog, Transition, TransitionChild } from "@headlessui/react";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";

const BookListIndexForm = ({
    openMemberForm,
    setOpenMemberForm,
    formData,
    createIndexTitle,
    updateIndexTitle,
    bookDetail,
    currentIndexDetail
}) => {
    const [formVisibility, setFormVisibility] = useState({});
    const [indexTitle, setIndexTitle] = useState(currentIndexDetail?.title || "");

    const resetForm = () => {
        setOpenMemberForm(!openMemberForm);
    }

    return (
        <>
            <Transition appear show={openMemberForm} as={Fragment}>
                <Dialog as="div" className="relative z-40" onClose={() => { }}>
                    <TransitionChild
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </TransitionChild>
                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <TransitionChild
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <div className="flex justify-between pb-4 border-b border-gray400">
                                        <div className="text-xl font-semibold text-orange-600">
                                            Book List Index Form
                                        </div>
                                        <Icon
                                            path={mdiClose}
                                            size={1}
                                            className="cursor-pointer text-[#D4D4D4] hover:text-[#A5A5A5] transition-all duration-150"
                                            onClick={() => {
                                                setOpenMemberForm(false);
                                            }}
                                        />
                                    </div>
                                    <div className="p-6">
                                        <div className="px-10 text-[14] text-[#212121]">
                                            Book Name : {formData.title}
                                        </div>
                                        <div className="flex flex-col">
                                            <div className="flex-grow flex">
                                                <div className="max-w-md w-full">
                                                    <div className="bg-white w-full px-10 py-[30px] rounded-[10px]">
                                                        <div className="mb-5">
                                                            <Textarea
                                                                label="Book Title"
                                                                className=" w-full"
                                                                value={indexTitle}
                                                                onChange={(e) => setIndexTitle(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-right flex justify-end mt-0">
                                                    <div className="mr-9">
                                                        <Button
                                                            color="red"
                                                            variant="outlined"
                                                            className="capitalize text-base"
                                                            onClick={() => resetForm()}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </div>
                                                    <div>
                                                        <Button
                                                            className="capitalize text-base bg-orange-600"
                                                            onClick={() =>
                                                                currentIndexDetail?._id
                                                                    ? updateIndexTitle(currentIndexDetail?._id, indexTitle)
                                                                    : createIndexTitle(bookDetail._id, indexTitle)
                                                            }
                                                        >
                                                            {currentIndexDetail?._id ? "Update" : "Create"}
                                                        </Button>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </TransitionChild>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}

export default BookListIndexForm;