import React from "react";
import { useRoutes } from "react-router-dom";
import { Login } from "../../pages/Auth";
import { MainLayout } from "../../Components";
import BannerRouter from "../app-router/BannerRouter";
import AttendanceRouter from "../app-router/AttendanceRouter";
import VideoRouter from "../app-router/VideoRouter";
import NewentryRouter from "../app-router/NewentryRouter";
import MantraLekhanRouter from "../app-router/MantralekhanRouter";
import AudioRouter from "../app-router/AudioRouter";
import ReportRouter from "../app-router/ReportRouter";
import FormRouter from "../app-router/FormRouter";
import BookListRouter from "../app-router/BookListRouter";

export default function MainRouter() {
  const MainRoute = [
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "photo&banner/*",
      element: (
        <MainLayout>
          <BannerRouter />
        </MainLayout>
      ),
    },
    {
      path: "attendance/*",
      element: (
        <MainLayout>
          <AttendanceRouter />
        </MainLayout>
      ),
    },
    {
      path: "video/*",
      element: (
        <MainLayout>
          <VideoRouter />
        </MainLayout>
      ),
    },
    {
      path: "audio/*",
      element: (
        <MainLayout>
          <AudioRouter />
        </MainLayout>
      ),
    },
    {
      path: "newentry/*",
      element: (
        <MainLayout>
          <NewentryRouter />
        </MainLayout>
      ),
    },
    {
      path: "mantraLekhan/*",
      element: (
        <MainLayout>
          <MantraLekhanRouter />
        </MainLayout>
      ),
    },
    {
      path: "reports/*",
      element: (
        <MainLayout>
          <ReportRouter />
        </MainLayout>
      ),
    },
    {
      path: "form/*",
      element: (
        <MainLayout>
          <FormRouter />
        </MainLayout>
      ),
    },
    {
      path: "book/*",
      element: (
        <MainLayout>
          <BookListRouter />
        </MainLayout>
      ),
    },
  ];
  const MainRouter = useRoutes(MainRoute);
  return MainRouter;
}
