import React, { useEffect, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { Checkbox, Input, Radio, Typography } from "@material-tailwind/react";
import DateField from "../../../Common/Input/DateField";
import SelectField from "../../../Common/Input/SelectField";
import CreatableMultiselectMain from "../../../Common/Input/CreatableMultiselectMain";
import moment from "moment/moment";
import { s3Url } from "../../../../services/api.service";
import {
  SABHA_TYPES,
  genderType,
} from "../../../../constants/common.constants";
import { Icon } from "@iconify/react";
import { saveAs } from "file-saver";
import { Disclosure, Switch } from "@headlessui/react";
import { useSelector } from "react-redux";
import AlertModal from "../../../Common/CustomConfirmationModal/CustomConfirmationModal";
import ImgPreviewModal from "../../../Common/ImgPreviewModal/ImgPreviewModal";
import countryCodeData from "../../../../constants/CountryCodes.json";
import ImageCropModal from "../../../Common/ImageCropModal.js/ImageCrop";
import {
  getProfileImage,
  getRoleName,
  toastr,
} from "../../../../services/common.service";
import GroupInfo from "./GroupInfo";
import DatePickerField from "../../../Common/Rsuite-Datepicker";
import {
  calculateAge,
  disableFutureDates,
} from "../../../../utils/calculateAge";

const createOption = (label) => ({
  label,
  value: label.toLowerCase().replace(/\W/g, ""),
});

const defaultOptions = [
  createOption("One"),
  createOption("Two"),
  createOption("Three"),
];

const bloodGroupOptions = [
  { label: "O+", value: "O+" },
  { label: "O-", value: "O-" },
  { label: "A+", value: "A+" },
  { label: "A-", value: "A-" },
  { label: "B+", value: "B+" },
  { label: "B-", value: "B-" },
  { label: "AB+", value: "AB+" },
  { label: "AB-", value: "AB-" },
];

const martialStatus = [
  {
    label: "Married",
    value: true,
  },
  {
    label: "Unmarried",
    value: false,
  },
];

const MemberProfile = (props) => {
  const {
    profileData,
    setMemberDetails,
    updateMember,
    isNewUser = false,
    isHead = false,
    unblockedUserAccount,
    changeUserType,
    handleCloseProfile,
    isReadable = false,
  } = props;

  const {
    societyList,
    streetNameList,
    cityList,
    stateList,
    countryList,
    villageList,
    occupationList,
    educationList,
  } = useSelector((state) => state.CommonFields);
  const { flagList } = useSelector((state) => state.MemberFlagsReducer);
  const [showConfirmUpdateModal, setShowConfirmUpdateModal] = useState(false);
  const [openImgPreviewModal, setOpenImgPreviewModal] = useState(false);
  const [openImageCropModal, setOpenImageCropModal] = useState(false);
  const [age, setAge] = useState();

  const defaultSelectedCountry = [
    {
      label: "India +91",
      value: "91",
      code: "IN",
    },
  ];

  useEffect(() => {
    if (profileData?.birthDate) {
      let ageCount = calculateAge(profileData?.birthDate);
      setAge(ageCount);
    } else {
      setAge(0);
    }
  }, [profileData]);

  const handleOpenImgPreview = () => {
    setOpenImgPreviewModal(!openImgPreviewModal);
  };

  const handleClosePreview = () => {
    setOpenImgPreviewModal(false);
  };

  const attendeeOf = profileData?.attendeeOf || [];

  const role = getRoleName(attendeeOf && attendeeOf);

  const downloadImg = () => {
    saveAs(
      profileData?.profileImgUrl
        ? `${s3Url}${profileData?.profileImgUrl}`
        : "/assets/image/profilepicture.png",
      "member.jpg"
    );
  };

  const handleOpenUpdateModal = () => {
    setShowConfirmUpdateModal(!showConfirmUpdateModal);
  };

  const handleProfileUpdate = () => {
    setShowConfirmUpdateModal(false);
  };

  const handleCloseModal = (value) => {
    if (value) {
      updateMember(true);
      toastr.show("Your Profile updated successfuly", "success");
    }
    setShowConfirmUpdateModal(false);
  };

  const handleOpenWhatsApp = (countryCode, phoneno) => {
    const message = "Jay Swaminarayan";
    // const url = `https://wa.me/${countryCode}${phoneno}?text=${encodeURIComponent(message)}`
    // const url = `https://api.whatsapp.com/send?phone=${countryCode}${phoneno}&text=${encodeURIComponent(message)}`
    const url = `https://web.whatsapp.com/send?phone=${countryCode}${phoneno}&text=${encodeURIComponent(
      message
    )}`;
    window.open(url, "_blank");
  };

  let selectedOptions = [];
  if (profileData?.skills?.length > 0) {
    selectedOptions = profileData?.skills.map((member) => ({
      label: member?.label || member,
      value: member || member?.label,
    }));
  }

  const profilePhoto = getProfileImage(profileData, profileData?.gender);

  return (
    <>
      <div className="w-full top-0 flex items-center justify-between sticky bg-white shadow-md p-2 z-10">
        <div>
          <button className="p-2" onClick={() => handleCloseProfile(false)}>
            <Icon
              icon="streamline:move-right-solid"
              className="cursor-pointer text-primary"
              fontSize={25}
            />
          </button>
        </div>
        <div className="flex items-center">
          <span className="text-sm mr-2">A/C Blocked</span>
          <Switch
            checked={profileData?.loginRetryLimit >= 10}
            disabled={profileData?.loginRetryLimit < 10}
            onChange={() =>
              profileData?.loginRetryLimit >= 10 && unblockedUserAccount()
            }
            className={`${
              profileData?.loginRetryLimit >= 10 ? "bg-blue-600" : "bg-gray-200"
            } relative inline-flex h-6 w-11 items-center rounded-full`}
          >
            <span className="sr-only">Enable notifications</span>
            <span
              className={`${
                profileData?.loginRetryLimit >= 10
                  ? "translate-x-6"
                  : "translate-x-1"
              } inline-block h-4 w-4 transform rounded-full bg-white transition`}
            />
          </Switch>
        </div>
        <div className="flex items-center">
          <span className="text-sm mr-2">Mantra. User</span>
          <Switch
            checked={profileData?.isMantraLekhanUser}
            onChange={() => changeUserType()}
            className={`${
              profileData?.isMantraLekhanUser === true
                ? "bg-blue-600"
                : "bg-gray-200"
            } relative inline-flex h-6 w-11 items-center rounded-full`}
          >
            <span className="sr-only">Enable notifications</span>
            <span
              className={`${
                profileData?.isMantraLekhanUser === true
                  ? "translate-x-6"
                  : "translate-x-1"
              } inline-block h-4 w-4 transform rounded-full bg-white transition`}
            />
          </Switch>
        </div>
        {!isReadable && (
          <div>
            <button
              className="inline-flex items-center bg-primary min-w-[80px] justify-center text-white text-sm px-1 py-1 rounded-md"
              onClick={() => handleOpenUpdateModal()}
            >
              Update
            </button>
          </div>
        )}
      </div>
      <div className="p-3 flex flex-col justify-between relative">
        <div className="py-2">
          <div className="w-full flex justify-center mb-3">
            <div className="relative w-[130px] h-[130px] 2xl:w-[180px] 2xl:h-[180px] group">
              <img
                src={profilePhoto}
                alt="avtar"
                className="w-full h-full rounded-full object-cover"
              />
              <div className="absolute w-full rounded-full h-full flex items-center justify-center xl:gap-x-4 2xl:gap-x-5 bg-[rgba(0,0,0,0.40)] opacity-0 inset-0 hover:opacity-100 transition-all duration-300 ease-[ease]">
                <button
                  className="w-7 h-7 2xl:w-10 2xl:h-10 flex items-center justify-center p-1.5 rounded-full transition-all duration-300 ease-[ease]"
                  onClick={handleOpenImgPreview}
                >
                  <Icon
                    icon="lucide:eye"
                    className="text-2xl 2xl:text-3xl hover:scale-[1.06]"
                    color="#fff"
                  />
                </button>

                <button
                  className="w-7 h-7 2xl:w-10 2xl:h-10 flex bg-white border-2 absolute bottom-0 left-2 border-white  items-center justify-center p-1.5 rounded-full transition-all duration-300 ease-[ease]"
                  onClick={() => {
                    setOpenImageCropModal(!openImageCropModal);
                  }}
                >
                  <label className="cursor-pointer">
                    <Icon
                      icon="material-symbols:photo-camera-outline"
                      className="text-base 2xl:text-2xl hover:scale-[1.06]"
                      color="#000"
                    />
                  </label>
                </button>
                <button
                  className="w-7 h-7 2xl:w-10 2xl:h-10 flex items-center justify-center bg-white border-2 absolute bottom-0 right-2 border-white p-1.5 rounded-full transition-all duration-300 ease-[ease]"
                  onClick={downloadImg}
                >
                  <Icon
                    icon="fe:download"
                    className="text-base 2xl:text-3xl hover:scale-[1.06]"
                    color="#000"
                  />
                </button>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center gap-2">
            <div className="text-sm font-semibold">SMV NO :</div>
            <div>{profileData?.SMVNo}</div>
          </div>
        </div>
        <div className="flex-grow">
          <div className="">
            {SABHA_TYPES.map((eachSabha, indx) => {
              const attendeeDetails = attendeeOf.find(
                (d) => d.sabhaId === eachSabha._id
              );
              let role = getRoleName(attendeeDetails);
              if (attendeeDetails) {
                return (
                  <div
                    className="col-span-2 flex gap-3 p-2 border border-[#b0bec5] rounded-lg mb-3"
                    key={indx}
                  >
                    <div className="flex items-center">
                      <div
                        className={`w-12 h-12 rounded-full border-2  uppercase font-bold grid place-items-center text-2xl ${
                          eachSabha._id === "6533aaf85da1a84b9ad7a707"
                            ? "text-pink-300 border-pink-300"
                            : "text-primary border-primary"
                        }`}
                      >
                        {(attendeeDetails?.sabhaName).charAt(0).toUpperCase()}
                      </div>
                    </div>
                    <div className="flex flex-col justify-center items-start flex-grow">
                      <div className="mr-5 text-blue-gray-700">
                        <span className="font-semibold">GP :</span>
                        {attendeeDetails.groupNo}
                      </div>
                      <div className="mr-5 text-blue-gray-700">
                        <span className="font-semibold">Dept :</span>{" "}
                        {attendeeDetails?.departmentInfo?.departmentName}
                      </div>
                      <div className="text-blue-gray-700">
                        <span className="font-semibold">User Type :</span>{" "}
                        {role && role}
                      </div>
                      <div className="text-blue-gray-700">
                        <span className="font-semibold">HOD :</span>{" "}
                        {attendeeDetails?.departmentInfo?.hodName}
                      </div>
                    </div>
                  </div>
                );
              }
              return null;
            })}
            <div className="mt-3">
              <Disclosure defaultOpen>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between rounded-lg bg-primary bg-opacity-10 px-4 py-2.5 text-left font-medium text-primary-900 focus:outline-none focus-visible:ring">
                      <span className="text-primary">Personal Info.</span>
                      <Icon
                        icon={"mdi:chevron-down"}
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } text-2xl text-primary transition-transform duration-200`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
                      <div className="grid 2xl:grid-cols-2 gap-3">
                        <div>
                          <Input
                            label="First Name"
                            className="max-w-1/2 w-full !text-base"
                            value={profileData?.firstName || ""}
                            onChange={(e) =>
                              setMemberDetails("firstName", e?.target?.value)
                            }
                            disabled={isReadable}
                          />
                        </div>
                        <div>
                          <Input
                            label="Middle Name"
                            className="max-w-1/2 w-full !text-base"
                            value={profileData?.middleName || ""}
                            onChange={(e) =>
                              setMemberDetails("middleName", e?.target?.value)
                            }
                            disabled={isReadable}
                          />
                        </div>
                        <div>
                          <Input
                            label="Last Name"
                            className="max-w-1/2 w-full !text-base"
                            value={profileData?.lastName || ""}
                            onChange={(e) =>
                              setMemberDetails("lastName", e?.target?.value)
                            }
                            disabled={isReadable}
                          />
                        </div>
                        <div className="flex grow-0">
                          <div className="w-[100px]">
                            <SelectField
                              className="placeholder:gray100"
                              id="041"
                              isMulti={false}
                              placeholder="Select Country"
                              options={countryCodeData}
                              isSearchable={true}
                              defaultValue={defaultSelectedCountry}
                              value={{
                                label: `+${profileData?.countryCode || "91"}`,
                                value: profileData?.countryCode || "91",
                              }}
                              defaultOptions={countryCodeData.map(
                                (countyObj) => ({
                                  label: `${countyObj.name} +${countyObj.dial_code}`,
                                  value: countyObj.dial_code || "",
                                })
                              )}
                              menuWidth="230px"
                              menuPosition="fixed"
                              onChange={(e) => {
                                setMemberDetails(
                                  "countryCode",
                                  String(e?.value) || "91"
                                );
                              }}
                              isDisabled={isReadable}
                            />
                          </div>
                          <div className="pl-1 flex-grow">
                            <div className="relative">
                              <input
                                placeholder="Mobile Number"
                                className="peer h-10 max-h-[38px] w-full rounded-[7px] border text-black border-blue-gray-200 bg-transparent px-3 py-2.5 focus:outline-0"
                                required={true}
                                maxLength={10}
                                min={10}
                                value={profileData?.mobile || ""}
                                onChange={(value) =>
                                  setMemberDetails(
                                    "mobile",
                                    value?.target?.value
                                  )
                                }
                                disabled={isReadable}
                              />
                            </div>
                          </div>
                          {profileData?.mobile ? (
                            <span className="w-12 h-9 border border-gray400 rounded-lg transition-all duration-150 flex justify-center items-center mr-1 cursor-pointer ml-1">
                              <button
                                onClick={() => {
                                  handleOpenWhatsApp(
                                    profileData?.countryCode,
                                    profileData?.mobile
                                  );
                                }}
                              >
                                <Icon
                                  icon="mdi-whatsapp"
                                  className="text-green-500 text-2xl"
                                />
                              </button>
                            </span>
                          ) : (
                            <></>
                          )}
                        </div>

                        <div className="flex shrink ">
                          <div className="w-[100px]">
                            <SelectField
                              className="placeholder:gray100"
                              id="042"
                              isMulti={false}
                              placeholder="Select Country"
                              options={countryCodeData}
                              isSearchable={true}
                              defaultValue={defaultSelectedCountry}
                              value={{
                                label: `+${profileData?.countryCode2 || "91"}`,
                                value: profileData?.countryCode2,
                              }}
                              defaultOptions={countryCodeData.map(
                                (countyObj) => ({
                                  label: `${countyObj.name} +${countyObj.dial_code}`,
                                  value: countyObj.dial_code,
                                })
                              )}
                              menuWidth="230px"
                              menuPosition="fixed"
                              onChange={(e) => {
                                setMemberDetails(
                                  "countryCode2",
                                  String(e?.value) || "91"
                                );
                              }}
                              isDisabled={isReadable}
                            />
                          </div>
                          <div className="flex-grow pl-1">
                            <div className="relative w-full">
                              <input
                                placeholder="Mobile Number 2"
                                className="peer h-10 max-h-[38px] w-full rounded-[7px] text-black border border-blue-gray-200 bg-transparent px-3 py-2.5 focus:outline-0"
                                value={profileData?.mobile2 || ""}
                                maxLength={10}
                                min={10}
                                onChange={(value) =>
                                  setMemberDetails(
                                    "mobile2",
                                    value?.target?.value
                                  )
                                }
                                disabled={isReadable}
                              />
                            </div>
                          </div>
                          {profileData?.mobile2 ? (
                            <span className="w-12 h-9 border border-gray400 rounded-lg transition-all duration-150 flex justify-center items-center mr-1 cursor-pointer ml-1">
                              <button
                                onClick={() => {
                                  handleOpenWhatsApp(
                                    profileData?.countryCode2,
                                    profileData?.mobile2
                                  );
                                }}
                              >
                                <Icon
                                  icon="mdi-whatsapp"
                                  className="text-green-500 text-2xl"
                                />
                              </button>
                            </span>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div>
                          <Input
                            label="Email"
                            type="email"
                            className="max-w-1/2 w-full !text-base"
                            value={profileData?.email || ""}
                            onChange={(e) =>
                              setMemberDetails("email", e?.target?.value)
                            }
                            disabled={isReadable}
                          />
                        </div>
                        <div>
                          <CreatableMultiselectMain
                            className="placeholder:gray100"
                            id="07"
                            isMulti={false}
                            placeholder="Occupation"
                            value={
                              profileData?.occupation
                                ? [
                                    {
                                      label: profileData?.occupation,
                                      value: profileData?.occupation,
                                    },
                                  ]
                                : []
                            }
                            options={occupationList}
                            onChange={(e) => {
                              setMemberDetails("occupation", e?.value);
                            }}
                            isDisabled={isReadable}
                          />
                        </div>
                        <div>
                          <CreatableMultiselectMain
                            className="placeholder:gray100"
                            id="07"
                            isMulti={false}
                            placeholder="Education"
                            value={
                              profileData?.education
                                ? [
                                    {
                                      label: profileData?.education,
                                      value: profileData?.education,
                                    },
                                  ]
                                : []
                            }
                            options={educationList}
                            onChange={(e) => {
                              setMemberDetails("education", e?.value);
                            }}
                            isDisabled={isReadable}
                          />
                        </div>
                        <div>
                          <Input
                            label="Remark"
                            className="max-w-1/2 w-full !text-base"
                            value={profileData?.remark || ""}
                            onChange={(e) =>
                              setMemberDetails("remark", e?.target?.value)
                            }
                            disabled={isReadable}
                          />
                        </div>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
            <div className="mt-3">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between rounded-lg bg-primary bg-opacity-10 px-4 py-2.5 text-left font-medium text-primary focus:outline-none focus-visible:ring">
                      <span className="text-primary">Address Info.</span>
                      <Icon
                        icon={"mdi:chevron-down"}
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } text-2xl text-primary transition-transform duration-200`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
                      <div className="grid 2xl:grid-cols-2 gap-3">
                        <div>
                          <Input
                            label="Home /Flat no."
                            className="max-w-1/2 w-full !text-base"
                            value={
                              (profileData?.blockNo ??
                                profileData?.address?.blockNo) ||
                              ""
                            }
                            onChange={(e) =>
                              setMemberDetails("blockNo", e?.target?.value)
                            }
                            disabled={isReadable}
                          />
                        </div>
                        <div>
                          <CreatableMultiselectMain
                            className="placeholder:gray100"
                            id="07"
                            isMulti={false}
                            placeholder="Select Society"
                            options={societyList}
                            value={
                              profileData?.society ||
                              profileData?.address?.society
                                ? [
                                    {
                                      label:
                                        profileData?.society ||
                                        profileData?.address?.society,
                                      value:
                                        profileData?.society ||
                                        profileData?.address?.society,
                                    },
                                  ]
                                : []
                            }
                            onChange={(e) => {
                              setMemberDetails("society", e?.value);
                            }}
                            isDisabled={isReadable}
                          />
                        </div>
                        <div>
                          <CreatableMultiselectMain
                            className="placeholder:gray100"
                            id="07"
                            isMulti={false}
                            placeholder="Area/Street"
                            options={streetNameList}
                            value={
                              profileData?.streetName ||
                              profileData?.address?.streetName
                                ? [
                                    {
                                      label:
                                        profileData?.streetName ||
                                        profileData?.address?.streetName,
                                      value:
                                        profileData?.streetName ||
                                        profileData?.address?.streetName,
                                    },
                                  ]
                                : []
                            }
                            onChange={(e) => {
                              setMemberDetails("streetName", e?.value);
                            }}
                            isDisabled={isReadable}
                          />
                        </div>
                        <div>
                          <CreatableMultiselectMain
                            className="placeholder:gray100"
                            id="07"
                            isMulti={false}
                            placeholder="City"
                            value={
                              profileData?.city || profileData?.address?.city
                                ? [
                                    {
                                      label:
                                        profileData?.city ||
                                        profileData?.address?.city,
                                      value:
                                        profileData?.city ||
                                        profileData?.address?.city,
                                    },
                                  ]
                                : []
                            }
                            options={cityList}
                            onChange={(e) => {
                              setMemberDetails("city", e?.value);
                            }}
                            isDisabled={isReadable}
                          />
                        </div>
                        <div>
                          <CreatableMultiselectMain
                            className="placeholder:gray100"
                            id="07"
                            isMulti={false}
                            placeholder="State"
                            value={
                              profileData?.state || profileData?.address?.state
                                ? [
                                    {
                                      label:
                                        profileData?.state ||
                                        profileData?.address?.state,
                                      value:
                                        profileData?.state ||
                                        profileData?.address?.state,
                                    },
                                  ]
                                : []
                            }
                            options={stateList}
                            onChange={(e) => {
                              setMemberDetails("state", e?.value);
                            }}
                            isDisabled={isReadable}
                          />
                        </div>
                        <div>
                          <CreatableMultiselectMain
                            className="placeholder:gray100"
                            id="07"
                            isMulti={false}
                            placeholder="Country"
                            value={
                              profileData?.country ||
                              profileData?.address?.country
                                ? [
                                    {
                                      label:
                                        profileData?.country ||
                                        profileData?.address?.country,
                                      value:
                                        profileData?.country ||
                                        profileData?.address?.country,
                                    },
                                  ]
                                : []
                            }
                            options={countryList}
                            onChange={(e) => {
                              setMemberDetails("country", e?.value);
                            }}
                            isDisabled={isReadable}
                          />
                        </div>
                        <div>
                          <Input
                            label="Pincode"
                            className="max-w-1/2 w-full !text-base"
                            value={
                              (profileData?.postalCode ??
                                profileData?.address?.postalCode) ||
                              ""
                            }
                            onChange={(e) =>
                              setMemberDetails(
                                "postalCode",
                                e?.target?.value ? Number(e.target.value) : ""
                              )
                            }
                            disabled={isReadable}
                          />
                        </div>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
            <div className="mt-3">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between rounded-lg px-4 py-2.5 text-left font-medium bg-primary bg-opacity-10 focus:outline-none focus-visible:ring">
                      <span className="text-primary">Other Info.</span>
                      <Icon
                        icon={"mdi:chevron-down"}
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } text-2xl text-primary transition-transform duration-200`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
                      <div className="grid 2xl:grid-cols-2 gap-3">
                        <div className="flex items-center gap-2">
                          <DatePickerField
                            placeholder="Date of Birth"
                            value={
                              profileData?.birthDate
                                ? new Date(profileData?.birthDate)
                                : null
                            }
                            cleanable={false}
                            disabledDate={disableFutureDates}
                            onChange={(e) =>
                              setMemberDetails(
                                "birthDate",
                                moment(e).toISOString()
                              )
                            }
                          />
                          <div className="w-1/5 bg-white appearance-none text-sm h-9 text-center border border-[#b0bec5] text-black900 outline-none px-2.5 py-2 rounded-lg ">
                            {age > 0 ? age : "-"}
                          </div>
                        </div>
                        <div>
                          <DatePickerField
                            placeholder="Date Of Marriage"
                            format="dd/MM/yyyy"
                            value={
                              profileData?.marriageDate
                                ? new Date(profileData?.marriageDate)
                                : null
                            }
                            disabledDate={disableFutureDates}
                            onChange={(e) =>
                              setMemberDetails(
                                "marriageDate",
                                moment(e).toISOString()
                              )
                            }
                            readOnly={!profileData?.maritalStatus || isReadable}
                            disabled={!profileData?.maritalStatus || isReadable}
                          />
                        </div>
                        <div>
                          <CreatableMultiselectMain
                            className="placeholder:gray100"
                            id="07"
                            isMulti={false}
                            placeholder="Select married status"
                            value={
                              profileData?.maritalStatus
                                ? [
                                    {
                                      label: "Married",
                                      value: true,
                                    },
                                  ]
                                : [{ label: "Unmarried", value: false }]
                            }
                            options={martialStatus}
                            onChange={(e) => {
                              setMemberDetails(
                                "maritalStatus",
                                Boolean(e?.value)
                              );
                            }}
                            isDisabled={isReadable}
                          />
                        </div>
                        <div>
                          <CreatableMultiselectMain
                            className="placeholder:gray100"
                            id="07"
                            isMulti={false}
                            placeholder="Select gender"
                            value={
                              profileData?.gender
                                ? [
                                    {
                                      label: profileData?.gender,
                                      value: profileData?.gender,
                                    },
                                  ]
                                : []
                            }
                            options={genderType}
                            onChange={(e) => {
                              setMemberDetails("gender", e?.value);
                            }}
                            isDisabled={isReadable}
                          />
                        </div>
                        <div>
                          <CreatableMultiselectMain
                            className="placeholder:gray100"
                            id="07"
                            isMulti={false}
                            placeholder="Native Village"
                            value={
                              profileData?.village
                                ? [
                                    {
                                      label: profileData?.village,
                                      value: profileData?.village,
                                    },
                                  ]
                                : []
                            }
                            options={villageList}
                            onChange={(e) => {
                              setMemberDetails("village", e?.value);
                            }}
                            isDisabled={isReadable}
                          />
                        </div>
                        <div>
                          <CreatableMultiselectMain
                            className="placeholder:text-black900"
                            id="07"
                            isMulti={false}
                            placeholder="Blood Group"
                            options={bloodGroupOptions}
                            value={
                              profileData?.bloodGroup
                                ? [
                                    {
                                      value: profileData?.bloodGroup,
                                      label: profileData?.bloodGroup,
                                    },
                                  ]
                                : []
                            }
                            onChange={(e) => {
                              setMemberDetails("bloodGroup", e?.value);
                            }}
                            isDisabled={isReadable}
                          />
                        </div>
                        <div>
                          <Input
                            label="Referrer Name"
                            className="max-w-1/2 w-full !text-base"
                            value={profileData?.referrerName || ""}
                            onChange={(e) =>
                              setMemberDetails("referrerName", e?.target?.value)
                            }
                            disabled={isReadable}
                          />
                        </div>
                        <div className="flex">
                          <div className="w-[100px]">
                            <SelectField
                              className="placeholder:gray100"
                              id="041"
                              isMulti={false}
                              placeholder="Select Country"
                              options={countryCodeData}
                              isSearchable={true}
                              defaultValue={defaultSelectedCountry}
                              value={{
                                label: `+${
                                  profileData?.referrerCountryCode || "91"
                                }`,
                                value: profileData?.referrerCountryCode,
                              }}
                              defaultOptions={countryCodeData.map(
                                (countyObj) => ({
                                  label: `${countyObj.name} +${countyObj.dial_code}`,
                                  value: countyObj.dial_code,
                                })
                              )}
                              menuWidth="230px"
                              menuPosition="fixed"
                              onChange={(e) => {
                                setMemberDetails(
                                  "referrerCountryCode",
                                  String(e?.value || "91")
                                );
                              }}
                              isDisabled={isReadable}
                            />
                          </div>
                          <div className="flex-grow pl-1">
                            <div className="relative">
                              <input
                                placeholder="Referrer Mobile"
                                className="peer h-10 max-h-[38px] w-full rounded-[7px] text-black border border-blue-gray-200 bg-transparent px-3 py-2.5 focus:outline-0"
                                value={profileData?.referrerMobile || ""}
                                maxLength={10}
                                min={10}
                                onChange={(value) =>
                                  setMemberDetails(
                                    "referrerMobile",
                                    value?.target?.value
                                  )
                                }
                                disabled={isReadable}
                              />
                            </div>
                          </div>
                          {profileData?.referrerMobile ? (
                            <span className="w-12 h-9 border border-gray400 rounded-lg transition-all duration-150 flex justify-center items-center mr-1 cursor-pointer ml-1">
                              <button
                                onClick={() => {
                                  handleOpenWhatsApp(
                                    profileData?.referrerCountryCode,
                                    profileData?.referrerMobile
                                  );
                                }}
                              >
                                <Icon
                                  icon="mdi-whatsapp"
                                  className="text-green-500 text-2xl"
                                />
                              </button>
                            </span>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div>
                          <Input
                            label="Entry Year"
                            className="max-w-1/2 w-full !text-base"
                            value={profileData?.entryYear ?? ""}
                            onChange={(e) =>
                              setMemberDetails(
                                "entryYear",
                                Number(e?.target?.value)
                              )
                            }
                            disabled={isReadable}
                          />
                        </div>
                        <div className="flex">
                          <div className="mr-6">
                            <Radio
                              name="active"
                              label="Active"
                              checked={profileData?.isActive}
                              onChange={() =>
                                setMemberDetails("isActive", true)
                              }
                              disabled={isReadable}
                            />
                          </div>
                          <div>
                            <Radio
                              name="inactive"
                              label="Inactive"
                              checked={!profileData?.isActive}
                              onChange={() =>
                                setMemberDetails("isActive", false)
                              }
                              disabled={isReadable}
                            />
                          </div>
                        </div>
                        <div>
                          <CreatableMultiselectMain
                            className="placeholder:gray100"
                            label="Skills"
                            placeholder="Enter Skills"
                            id="23"
                            isMulti={true}
                            value={selectedOptions || []}
                            setMemberDetails={setMemberDetails}
                            options={defaultOptions}
                            onChange={(e) => {
                              setMemberDetails("skills", e);
                            }}
                            isDisabled={isReadable}
                          />
                        </div>
                        <div className="">
                          <Checkbox
                            label={
                              <Typography className="poppins">
                                I-Card Given
                              </Typography>
                            }
                            checked={profileData?.icardGiven || false}
                            onChange={(value) =>
                              setMemberDetails(
                                "icardGiven",
                                value?.target?.checked
                              )
                            }
                          />
                        </div>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
            {!(isNewUser || isHead) && (
              <div className="mt-3">
                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full justify-between rounded-lg px-4 py-2.5 text-left font-medium bg-primary bg-opacity-10 focus:outline-none focus-visible:ring">
                        <span className="text-primary">Group Info.</span>
                        <Icon
                          icon={"mdi:chevron-down"}
                          className={`${
                            open ? "rotate-180 transform" : ""
                          } text-2xl text-primary transition-transform duration-200`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
                        <GroupInfo
                          profileData={profileData}
                          attendeeOf={attendeeOf}
                          isReadable={isReadable}
                        />
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </div>
            )}
            <div className="mt-3">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between rounded-lg px-4 py-2.5 text-left font-medium bg-primary bg-opacity-10 focus:outline-none focus-visible:ring">
                      <span className="text-primary">Members of.</span>
                      <Icon
                        icon={"mdi:chevron-down"}
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } text-2xl text-primary transition-transform duration-200`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
                      <div className="grid grid-cols-2 gap-3">
                        {flagList.map((flag) => {
                          if (flag?.isShow) {
                            return (
                              <div>
                                <div className="">
                                  <Checkbox
                                    label={
                                      <Typography className="poppins">
                                        {flag.name}
                                      </Typography>
                                    }
                                    checked={
                                      profileData?.[
                                        `${flag?.referenceField}`
                                      ] || false
                                    }
                                    onChange={(value) =>
                                      setMemberDetails(
                                        flag?.referenceField || false,
                                        value?.target?.checked
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            );
                          }
                        })}
                        {/* <div>
                          <div className="">
                            <Checkbox
                              label={
                                <Typography className="poppins">
                                  Foreigner
                                </Typography>
                              }
                              checked={profileData?.foreigner || false}
                              onChange={(value) =>
                                setMemberDetails(
                                  "foreigner",
                                  value?.target?.checked
                                )
                              }
                            />
                          </div>
                        </div> */}
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
            <div className="mt-3">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between rounded-lg bg-primary bg-opacity-10 px-4 py-2.5 text-left font-medium text-primary focus:outline-none focus-visible:ring">
                      <span className="text-primary">Foreigne Info.</span>
                      <Icon
                        icon={"mdi:chevron-down"}
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } text-2xl text-primary transition-transform duration-200`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
                      <div className="grid 2xl:grid-cols-2 gap-3">
                        <div>
                          <Input
                            label="Name of Institute"
                            className="max-w-1/2 w-full !text-base"
                            value={
                              (profileData?.foreign?.institute ??
                                profileData?.foreign?.institute) ||
                              ""
                            }
                            onChange={(e) =>
                              setMemberDetails(
                                "foreign_institute",
                                e?.target?.value
                              )
                            }
                            disabled={isReadable}
                          />
                        </div>
                        <div>
                          <Input
                            label="Foreine Since year"
                            className="max-w-1/2 w-full !text-base"
                            value={
                              (profileData?.foreign?.sinceYear ??
                                profileData?.foreign?.sinceYear) ||
                              ""
                            }
                            onChange={(e) =>
                              setMemberDetails(
                                "foreign_sinceYear",
                                e?.target?.value
                              )
                            }
                            disabled={isReadable}
                          />
                        </div>
                        <div>
                          <Input
                            label="Referrer Name"
                            className="max-w-1/2 w-full !text-base"
                            value={profileData?.foreign?.referrerName || ""}
                            onChange={(e) =>
                              setMemberDetails(
                                "foreign_referrerName",
                                e?.target?.value
                              )
                            }
                            disabled={isReadable}
                          />
                        </div>
                        <div className="flex">
                          <div className="w-[100px]">
                            <SelectField
                              className="placeholder:gray100"
                              id="041"
                              isMulti={false}
                              placeholder="Select Country"
                              options={countryCodeData}
                              isSearchable={true}
                              defaultValue={defaultSelectedCountry}
                              value={{
                                label: `+${
                                  profileData?.foreign?.referrerCountryCode ||
                                  "91"
                                }`,
                                value:
                                  profileData?.foreign?.referrerCountryCode,
                              }}
                              defaultOptions={countryCodeData.map(
                                (countyObj) => ({
                                  label: `${countyObj.name} +${countyObj.dial_code}`,
                                  value: countyObj.dial_code,
                                })
                              )}
                              menuWidth="230px"
                              menuPosition="fixed"
                              onChange={(e) => {
                                setMemberDetails(
                                  "foreign_referrerCountryCode",
                                  String(e?.value || "91")
                                );
                              }}
                              isDisabled={isReadable}
                            />
                          </div>
                          <div className="flex-grow pl-1">
                            <div className="relative">
                              <input
                                placeholder="Referrer Mobile"
                                className="peer h-10 max-h-[38px] w-full rounded-[7px] text-black border border-blue-gray-200 bg-transparent px-3 py-2.5 focus:outline-0"
                                value={
                                  profileData?.foreign?.referrerMobile || ""
                                }
                                maxLength={10}
                                min={10}
                                onChange={(value) =>
                                  setMemberDetails(
                                    "foreign_referrerMobile",
                                    value?.target?.value
                                  )
                                }
                                disabled={isReadable}
                              />
                            </div>
                          </div>
                          {profileData?.foreign?.referrerMobile ? (
                            <span className="w-12 h-9 border border-gray400 rounded-lg transition-all duration-150 flex justify-center items-center mr-1 cursor-pointer ml-1">
                              <button
                                onClick={() => {
                                  handleOpenWhatsApp(
                                    profileData?.foreign?.referrerCountryCode,
                                    profileData?.foreign?.referrerMobile
                                  );
                                }}
                              >
                                <Icon
                                  icon="mdi-whatsapp"
                                  className="text-green-500 text-2xl"
                                />
                              </button>
                            </span>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div>
                          <Input
                            label="My free time(Online seva)"
                            className="max-w-1/2 w-full !text-base"
                            value={
                              (profileData?.foreign?.seva ??
                                profileData?.foreign?.seva) ||
                              ""
                            }
                            onChange={(e) =>
                              setMemberDetails("foreign_seva", e?.target?.value)
                            }
                            disabled={isReadable}
                          />
                        </div>
                        <div>
                          <Input
                            label="Home /Flat no."
                            className="max-w-1/2 w-full !text-base"
                            value={profileData?.foreign?.address?.blockNo || ""}
                            onChange={(e) =>
                              setMemberDetails(
                                "foreign_blockNo",
                                e?.target?.value
                              )
                            }
                            disabled={isReadable}
                          />
                        </div>
                        <div>
                          <CreatableMultiselectMain
                            className="placeholder:gray100"
                            id="07"
                            isMulti={false}
                            placeholder="Select Society"
                            options={societyList}
                            value={
                              profileData?.foreign?.address?.society
                                ? [
                                    {
                                      label:
                                        profileData?.foreign?.address
                                          ?.society || "",
                                      value:
                                        profileData?.foreign?.address
                                          ?.society || "",
                                    },
                                  ]
                                : []
                            }
                            onChange={(e) => {
                              setMemberDetails("foreign_society", e?.value);
                            }}
                            isDisabled={isReadable}
                          />
                        </div>
                        <div>
                          <CreatableMultiselectMain
                            className="placeholder:gray100"
                            id="07"
                            isMulti={false}
                            placeholder="Area/Street"
                            options={streetNameList}
                            value={
                              profileData?.foreign?.address?.streetName
                                ? [
                                    {
                                      label:
                                        profileData?.foreign?.address
                                          ?.streetName,
                                      value:
                                        profileData?.foreign?.address
                                          ?.streetName,
                                    },
                                  ]
                                : []
                            }
                            onChange={(e) => {
                              setMemberDetails("foreign_streetName", e?.value);
                            }}
                            isDisabled={isReadable}
                          />
                        </div>
                        <div>
                          <CreatableMultiselectMain
                            className="placeholder:gray100"
                            id="07"
                            isMulti={false}
                            placeholder="City"
                            value={
                              profileData?.foreign?.address?.city
                                ? [
                                    {
                                      label:
                                        profileData?.foreign?.address?.city,
                                      value:
                                        profileData?.foreign?.address?.city,
                                    },
                                  ]
                                : []
                            }
                            options={cityList}
                            onChange={(e) => {
                              setMemberDetails("foreign_city", e?.value);
                            }}
                            isDisabled={isReadable}
                          />
                        </div>
                        <div>
                          <CreatableMultiselectMain
                            className="placeholder:gray100"
                            id="07"
                            isMulti={false}
                            placeholder="State"
                            value={
                              profileData?.foreign?.address?.state
                                ? [
                                    {
                                      label:
                                        profileData?.foreign?.address?.state,
                                      value:
                                        profileData?.foreign?.address?.state,
                                    },
                                  ]
                                : []
                            }
                            options={stateList}
                            onChange={(e) => {
                              setMemberDetails("foreign_state", e?.value);
                            }}
                            isDisabled={isReadable}
                          />
                        </div>
                        <div>
                          <CreatableMultiselectMain
                            className="placeholder:gray100"
                            id="07"
                            isMulti={false}
                            placeholder="Country"
                            value={
                              profileData?.foreign?.address?.country
                                ? [
                                    {
                                      label:
                                        profileData?.foreign?.address?.country,
                                      value:
                                        profileData?.foreign?.address?.country,
                                    },
                                  ]
                                : []
                            }
                            options={countryList}
                            onChange={(e) => {
                              setMemberDetails("foreign_country", e?.value);
                            }}
                            isDisabled={isReadable}
                          />
                        </div>
                        <div>
                          <Input
                            label="Pincode"
                            className="max-w-1/2 w-full !text-base"
                            value={
                              profileData?.foreign?.address?.postalCode || ""
                            }
                            onChange={(e) =>
                              setMemberDetails(
                                "foreign_postalCode",
                                e?.target?.value ? Number(e.target.value) : ""
                              )
                            }
                            disabled={isReadable}
                          />
                        </div>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
          </div>
        </div>
      </div>
      <AlertModal
        open={showConfirmUpdateModal}
        handleAction={handleProfileUpdate}
        handleCloseModal={handleCloseModal}
        messageText="Are you sure you want to update this profile?"
        submitBtnText="Update"
        buttonType="primary"
        icon="material-symbols-light:sync-saved-locally-outline"
      />
      <ImgPreviewModal
        previewImg={
          profileData?.profileImgUrl
            ? `${s3Url}${profileData?.profileImgUrl}`
            : profileData?.gender === "female"
            ? "/assets/image/female-avatar.jpg"
            : "/assets/image/profilepicture.png"
        }
        closeModal={handleClosePreview}
        show={openImgPreviewModal}
      />
      <ImageCropModal
        isOpen={openImageCropModal}
        isNewUser={isNewUser}
        setMemberDetails={setMemberDetails}
        handleImageUpdate={(croppedImg) =>
          setMemberDetails("profileImgUrl", croppedImg)
        }
        setOpenImageCropModal={setOpenImageCropModal}
        profileImage={
          profileData?.profileImgUrl
            ? `${s3Url}${profileData?.profileImgUrl}`
            : profileData?.gender === "female"
            ? "/assets/image/female-avatar.jpg"
            : "/assets/image/profilepicture.png"
        }
      />
    </>
  );
};

export default MemberProfile;
