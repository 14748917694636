import { api } from "../../../services/api.service";
import { toastr } from "../../../services/common.service";

export const getForm = async (queryObject) => {
  const response = await api.get("admin/forms", {
    params: queryObject
  });
  return response;
};

export const getFormSabha = async (userData) => {
  const response = await api.get('admin/forms/sabha', userData);
  return response;
}

export const createFormSabha = async (data) => {
  try {
    const response = await api.post("admin/forms/sabha/create", data);
    return response;
  } catch (error) {
    toastr.show(
      error.response?.data?.message ?? "Something went wrong",
      "error"
    );
  }
};

export const updateFormSabha = async (data) => {
  try {
    const response = await api.post("admin/forms/sabha/update", data);
    return response;
  } catch (error) {
    toastr.show(
      error.response?.data?.message ?? "Something went wrong",
      "error"
    );
  }
};

export const deleteSabha = async (data) => {
  const response = await api.post("admin/forms/sabha/delete", data);
  return response;
};

export const startAttendence = async (params) => {
  const response = await api.post("admin/forms/sabha/start", params);
  return response;
};

export const countAttendence = async (params) => {
  const response = await api.post("admin/forms/sabha/count", params);
  return response;
};

export const completeAttendence = async (params) => {
  const response = await api.post("admin/forms/sabha/complete", { sabhaId: params });
  return response;
};
