import React, { useEffect, useRef, useState } from "react";
import { Button } from "@material-tailwind/react";
import { Switch } from "@headlessui/react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Icon } from "@iconify/react";
import { motion } from "framer-motion";
import { useParams } from "react-router-dom";
import DeleteModal from "../Common/DeleteModal/DeleteModal";
import { toastr } from "../../services/common.service";
import { s3Url } from "../../services/api.service";
import {
  changeBookStatus,
  deleteBook,
  getAllBooks,
  reorderBooks,
} from "../photo-banner/actionCreator";
import AlertModal from "../Common/CustomConfirmationModal/CustomConfirmationModal";
import BooksUpload from "../photo-banner/BooksUpload";
import { useReactNavigator } from "../../utils/reactNavigator";

const columnHelper = createColumnHelper();

const DraggableRow = ({ row, reorderBook }) => {
  const [, dropRef] = useDrop({
    accept: "row",
    drop: (draggedRow) => reorderBook(draggedRow.index, row.index),
  });

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => row,
    type: "row",
  });

  return (
    <motion.tr
      ref={previewRef}
      initial={{ opacity: 1 }}
      animate={{ opacity: isDragging ? 0.5 : 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
    >
      <td ref={dropRef} className="text-start w-[50px]">
        <button ref={dragRef} className="cursor-grab">
          <Icon icon="grommet-icons:drag" className="text-xl" />
        </button>
      </td>
      {row.getVisibleCells().map((cell) => (
        <td key={cell.id} className="p-2">
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      ))}
    </motion.tr>
  );
};

const BookList = () => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [currentBookDetail, setCurrentBookDetail] = useState({});
  const [showAlertModel, setShowAlertModel] = useState(false);
  const [currentBookId, setCurrentBookId] = useState(null);

  const { navigate } = useReactNavigator();

  const openCreateLinkModal = (_id) => {
    setOpen(!open);
    const currentData = data.find((item) => item._id + "" === _id + "");
    setCurrentBookDetail(currentData);
  };

  const handleOpenDeleteModal = (_id) => {
    setShowAlertModel(true);
    setCurrentBookId(_id);
  };

  const reorderBook = (draggedRowIndex, targetRowIndex) => {
    data.splice(targetRowIndex, 0, data.splice(draggedRowIndex, 1)[0]);
    setData([...data]);
    const bookIds = data.map((d) => d._id);
    reorderBooks({ bookIds })
      .then(() => {
        getAllBooksDetail();
      })
      .catch((err) => {
        toastr.show("Something went wrong", "error");
      });
  };

  const getAllBooksDetail = async () => {
    try {
      const response = await getAllBooks();
      if (response) {
        setData(response.data);
      }
    } catch (err) {
      console.error("Error getting album detail:", err);
    }
  };

  const deleteBookConfirmation = async (status) => {
    if (status) {
      try {
        if (currentBookId) {
          await deleteBook({ _id: currentBookId });
          getAllBooksDetail();
          toastr.show("Delete book!", "success");
        }
      } catch (err) {
        toastr.show("Something went wrong", "error");
      }
    }
    setShowAlertModel(false);
  };

  const changeVisibility = async (bookId, status) => {
    try {
      const response = await changeBookStatus(bookId, status);
      if (response) {
        toastr.show("Book status changed successfully!", "success");
        getAllBooksDetail();
      }
    } catch (err) {
      console.error("Error changing book status:", err);
    }
  };

  useEffect(() => {
    getAllBooksDetail();
  }, []);

  const columns = [
    columnHelper.accessor("title", {
      cell: (info) => info.getValue(),
      header: () => <span>Title</span>,
    }),
    columnHelper.accessor("imageURL", {
      cell: (info) => (
        <img
          src={
            info.getValue()
              ? `${s3Url}${info.getValue()}`
              : "/assets/image/no-thumbnail.png"
          }
          className="h-[50px] rounded-lg"
        />
      ),
      header: () => <span>Thumbnail</span>,
    }),
    columnHelper.accessor("author", {
      cell: (info) => info.getValue(),
      header: () => <span>Author</span>,
    }),
    columnHelper.accessor("enabled", {
      cell: (info) => (
        <Switch
          // checked={info.getValue()}
          className={`${
            info.getValue() ? "bg-blue-600" : "bg-gray-200"
          } relative inline-flex h-6 w-11 items-center rounded-full`}
          onClick={() =>
            changeVisibility(info.row.original._id, !info.getValue())
          }
        >
          <span
            className={`${
              info.getValue() ? "translate-x-6" : "translate-x-1"
            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
          />
        </Switch>
      ),
      header: () => <span>Visibility</span>,
    }),
    columnHelper.display({
      header: () => <span className="text-center">Actions</span>,
      id: "actions",
      cell: (props) => {
        return (
          <span className="flex items-center justify-center">
            <span className="w-9 h-9 border border-black900 rounded-xl transition-all duration-150 flex justify-center items-center mr-2 cursor-pointer group text-black900">
              <button>
                <Icon
                  icon="ic:baseline-edit"
                  className="text-2xl transition-all duration-150 text-black900"
                  onClick={() => openCreateLinkModal(props.row.original?._id)}
                />
              </button>
            </span>
            <span className="w-9 h-9 border border-black900 rounded-xl transition-all duration-150 flex justify-center items-center mr-2 cursor-pointer group hover:text-black900">
              <button>
                <Icon
                  icon="material-symbols:delete"
                  className="transition-all duration-150 text-2xl text-black900"
                  onClick={() => handleOpenDeleteModal(props.row.original?._id)}
                />
              </button>
            </span>
            <span className="w-9 h-9 border border-black900 rounded-xl transition-all duration-150 flex justify-center items-center mr-2 cursor-pointer group hover:text-black900">
              <button
                onClick={() => {
                  navigate(`${props.row.original?._id}`, {
                    data: props.row.original,
                  });
                }}
              >
                <Icon
                  icon="ion:enter"
                  className="text-2xl transition-all duration-150 text-black900"
                />
              </button>
            </span>
          </span>
        );
      },
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id,
  });

  return (
    <>
      <div className="h-[calc(100vh_-_81px)] p-10 bg-[#F8F8F8] overflow-auto">
        <div className="flex items-center justify-between pb-[15px] mb-[20px] border-b-[#D7D7D7] border-b border-solid">
          <div className="text-[25px] text-[#212121]">Books List</div>
          <Button
            color="blue"
            className="flex items-center min-w-[150px]"
            onClick={() => openCreateLinkModal()}
          >
            <Icon icon="ic:baseline-plus" className="mr-1 text-2xl" /> Add Book
          </Button>
        </div>

        <div className="bg-white w-full px-6 py-[30px] overflow-hidden rounded-[10px] shadow-[1px_-1px_10px_0px_rgba(0,0,0,0.10)]">
          <div className="overflow-auto">
            <DndProvider backend={HTML5Backend}>
              <table className="w-full">
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => {
                    return (
                      <tr className="border-b border-stone-200 text-sm bg-[#F2F2F2] text-[#535353]">
                        <th className="text-left font-semibold p-2 min-w-[50px]"></th>
                        {headerGroup.headers.map((header) => {
                          return (
                            <th
                              key={header.id}
                              colSpan={header.colSpan}
                              className="text-left font-semibold p-2"
                            >
                              {header.isPlaceholder ? null : (
                                <div
                                  {...{
                                    className: header.column.getCanSort()
                                      ? "cursor-pointer select-none"
                                      : "last:text-center",
                                  }}
                                >
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                                </div>
                              )}
                            </th>
                          );
                        })}
                      </tr>
                    );
                  })}
                </thead>
                <tbody className="last:!border-b-0">
                  {table.getRowModel().rows.map((row) => (
                    <DraggableRow
                      key={row.id}
                      row={row}
                      reorderBook={reorderBook}
                    />
                  ))}
                </tbody>
              </table>
            </DndProvider>
          </div>
        </div>
      </div>

      <AlertModal
        open={showAlertModel}
        handleCloseModal={deleteBookConfirmation}
        messageText="Are you sure you want to delete this book?"
        submitBtnText="Confirm"
        buttonType="primary"
        icon="icon-park:go-end"
      />

      <BooksUpload
        open={open}
        setOpen={setOpen}
        currentBookDetail={currentBookDetail}
        getAllBooksDetail={getAllBooksDetail}
      />
    </>
  );
};

export default BookList;
