import React, { useState, useEffect, useMemo } from "react";
import lodash from "lodash";
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import AddUserModal from "./AddUserModal";
import { getUserPermission } from "./actionCreator";
import { toastr } from "../../services/common.service";
import { s3Url } from "../../services/api.service";
import { Icon } from "@iconify/react";

import { CONFIGURABLE_PERMISSIONS } from "./constants";
import SelectField from "../Common/Input/SelectField";

const Permissions = () => {
  const [userList, setUserList] = useState([]);
  const [filteredUserList, setFilteredUserList] = useState([]);
  const [showAdduerModal, setShowAdduerModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [columnVisibility, setColumnVisibility] = useState({
    id: "SMVNo",
    desc: true,
  });
  const [data, setData] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [columnPinning, setColumnPinning] = useState({
    right: ["action"],
  });
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [filters, setFilters] = useState({ gender: "", SMVNo: "" });

  const filterOptions = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
    { label: "All", value: "All" },
  ];

  const setPermissionFilter = (key, value) => {
    const updatedFilters = { ...filters, [key]: value };
    setFilters(updatedFilters);

    let _filteredMemberList = lodash.cloneDeep(userList);

    // Apply both filters (gender and SMVNo)
    if (updatedFilters.gender && updatedFilters.gender !== "All") {
      _filteredMemberList = _filteredMemberList.filter(
        (d) => d?.gender === updatedFilters.gender
      );
    }
    if (updatedFilters.SMVNo) {
      _filteredMemberList = _filteredMemberList.filter(
        (d) => d?.SMVNo == updatedFilters?.SMVNo
      );
    }

    setFilteredUserList(_filteredMemberList || userList);
  };

  const applyFilter = (selectedFilterOption) => {
    let _filteredMemberList = lodash.cloneDeep(userList);
    if (selectedFilterOption === "All") {
      setFilteredUserList(_filteredMemberList);
      return true;
    }
    _filteredMemberList = _filteredMemberList.filter(
      (d) => d?.gender === selectedFilterOption
    );
    setFilteredUserList(_filteredMemberList || userList);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "SMVNo",
        header: () => <span>SMV NO.</span>,
        cell: (info) => info.getValue(),
        id: "SMVNo",
        size: 150,
        maxSize: 150,
        enableSorting: true,
        sortDescFirst: false,
      },
      {
        accessorKey: "profileImgUrl",
        header: () => <span>Photo</span>,
        cell: (info) => {
          return (
            <img
              className=" min-w-[48px] max-w-[48px] h-12 rounded-full mr-3 object-cover"
              src={
                info.row.original.profileImgUrl
                  ? `${s3Url}${info.row.original.profileImgUrl}`
                  : info.row.original?.gender === "female"
                  ? "/assets/image/female-avatar.jpg"
                  : "/assets/image/profilepicture.png"
              }
            />
          );
        },
        enableSorting: false,
        id: "profileImgUrl",
        size: 150,
      },
      {
        accessorKey: "fullName",
        header: () => <span>Name.</span>,
        cell: (info) => (
          <div className="">
            {info.row.original?.firstName} {info.row.original?.middleName}{" "}
            {info.row.original?.lastName}
          </div>
        ),
        enableSorting: false,
        id: "fullName",
        // size: 450,
      },
      {
        accessorKey: "mobile",
        cell: (info) => info.getValue(),
        id: "mobile",
        size: 150,
        enableSorting: false,
      },
      {
        accessorKey: "Permissions",
        header: () => <span>Permissions</span>,
        cell: (info) => {
          return (
            <div className="flex items-center -space-x-2">
              {info.row.original?.permissions?.map((perm) => {
                return (
                  <div className="w-10 h-10 rounded-full flex items-center cursor-pointer justify-center border bg-teal-700 shadow-md text-white hover:z-10">
                    {CONFIGURABLE_PERMISSIONS[perm]?.shortCode}
                  </div>
                );
              })}
            </div>
          );
        },
        id: "Permissions",
        size: 150,
        enableSorting: false,
      },
      {
        header: () => <span className="text-center">Actions</span>,
        cell: (info) => {
          return (
            <button
              onClick={(evt) => {
                evt.stopPropagation();
                handleOpenUserModal(userList, info?.row?.original?.SMVNo);
              }}
            >
              <span className="w-9 h-9 border border-gray400 rounded-xl transition-all duration-150 flex justify-center items-center mr-1 cursor-pointer hover:border-black900 group hover:text-black900">
                <Icon
                  icon="mdi:account-edit"
                  size={1}
                  className="text-gray400 transition-all duration-150 group-hover:text-black900"
                />
              </span>
            </button>
          );
        },
        id: "action",
        size: 150,
        enableHiding: false,
      },
    ],
    [userList]
  );

  const [columnOrder, setColumnOrder] = useState(() =>
    columns?.map((c) => c.id)
  );

  useEffect(() => {
    getUserPermissionFun();
  }, []);

  useEffect(() => {
    setData(filteredUserList || []);
  }, [filteredUserList]);

  const getUserPermissionFun = async () => {
    try {
      const result = await getUserPermission();
      if (result?.data) {
        setUserList(result?.data || []);
        setData(result?.data || []);
      }
    } catch (err) {
      toastr.show("Failed !!", "error");
    }
  };

  const handleCloseUserModal = () => {
    setShowAdduerModal(false);
    setSelectedUser(null);
  };

  const handleOpenUserModal = (__userList, selectedSMVNo) => {
    if (__userList?.length && selectedSMVNo !== "") {
      const _selectedUser = __userList.filter(
        (d) => d?.SMVNo === selectedSMVNo
      )?.[0];
      setSelectedUser(_selectedUser);
    }
    setShowAdduerModal(true);
  };

  const table = useReactTable({
    data,
    columns,
    initialState: {
      sorting: [
        {
          id: "SMVNo",
          desc: false,
        },
      ],
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility,
      columnOrder,
      columnPinning,
      pagination,
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnOrderChange: setColumnOrder,
    onColumnPinningChange: setColumnPinning,
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    debugTable: true,
    debugHeaders: true,
    debugColumns: true,
  });

  return (
    <>
      <div class="flex w-full">
        <div className="p-3 flex-grow border border-stone-200 rounded-lg overflow-auto h-full w-full">
          <div className="p-4">
            <h1 className="text-lg font-semibold mb-6 text-black900">
              User Permissions
            </h1>
            <div className="flex items-start justify-between mb-4">
              <div className="flex items-center">
                <div className="w-36 relative mr-4">
                  <SelectField
                    className="placeholder:gray100 pl-1"
                    required={false}
                    placeholder="Select gender"
                    id="1"
                    defaultOptions={filterOptions}
                    // onChange={(e) => {
                    //   applyFilter(e?.value);
                    // }}
                    onChange={(e) => {
                      setPermissionFilter("gender", e?.value);
                    }}
                  />
                </div>
                <div className="w-56 relative">
                  <input
                    id="search-members"
                    type="text"
                    placeholder="Search SMVNo"
                    autoComplete="off"
                    className="border bg-white text-sm h-9 w-full text-black900 outline-none px-2.5 py-2 rounded-lg placeholder:text-stone-400 placeholder:text-sm caret-stone-400 placeholder:pl-0.5"
                    value={filteredUserList?.SMVNo}
                    onChange={(e) => {
                      setPermissionFilter("SMVNo", e.target.value);
                    }}
                  />
                  <Icon
                    icon="iconamoon:search-light"
                    className="absolute right-2 top-[50%] translate-y-[-50%] text-lg text-[#969696]"
                  />
                </div>
              </div>

              <button
                className="inline-flex items-center bg-primary text-white text-sm px-2 py-2 rounded-lg"
                onClick={() => handleOpenUserModal()}
              >
                <Icon
                  path="mdi:access-point-plus"
                  size={0.7}
                  className="inline mr-1"
                />
                Add User
              </button>
            </div>
            <div className="overflow-auto h-[calc(100vh_-_295px)]">
              <table className="w-full member-table relative">
                <thead className="sticky top-0">
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr
                      key={headerGroup.id}
                      className="border-b border-stone-200 text-sm text-left bg-[#F2F2F2] text-[#535353]"
                    >
                      {headerGroup.headers.map((header) => (
                        <th key={header.id} className="p-2">
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map((row) => (
                    <tr
                      key={row.id}
                      className="border-b border-stone-200 text-sm bg-white"
                    >
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id} className="p-2">
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex items-center justify-end gap-2 mt-3">
              <button
                className="border rounded w-8 h-8 grid place-items-center"
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              >
                <Icon icon="lucide:chevron-left" size={1} className="text-xl" />
              </button>
              <button
                className="border rounded w-8 h-8 grid place-items-center"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              >
                <Icon
                  icon="lucide:chevron-right"
                  size={1}
                  className="text-xl"
                />
              </button>
              <span className="flex items-center gap-1">
                <div>Page</div>
                <strong>
                  {table.getState().pagination.pageIndex + 1} of{" "}
                  {table.getPageCount().toLocaleString()}
                </strong>
              </span>
              <span className="flex items-center gap-1">
                | Go to page:
                <input
                  type="number"
                  defaultValue={table.getState().pagination.pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    table.setPageIndex(page);
                  }}
                  className="border p-1 rounded w-16 outline-none"
                />
              </span>
              <select
                value={table.getState().pagination.pageSize}
                onChange={(e) => {
                  table.setPageSize(Number(e.target.value));
                }}
                className="outline-none p-1 border rounded "
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      <AddUserModal
        open={showAdduerModal}
        handleCloseModal={handleCloseUserModal}
        getUserPermissionFun={getUserPermissionFun}
        selectedUser={selectedUser}
      />
    </>
  );
};

export default Permissions;
